import angular from 'angular';
import config from "../config";

export default angular
    .module(`${config.appName}.commonFilters`, [])
    .filter('htmlToPlainText', htmlToPlainText)
    .name

function htmlToPlainText() {
    'ngInject';

    return function(text) {
        return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
}


