import angular from 'angular'

import config from '../../config';


export default angular.module(`${config.appName}.cookie-expire`, [])
	.service('geolocatorCookieExpire', GeolocatorCookieExpire)
	.name

function GeolocatorCookieExpire() {
	'ngInject';

	let expirationDate = new Date();
	let ttl = parseInt('3,600'.replace(',', ''), 10) * 1000;
	expirationDate.setTime(expirationDate.getTime() + ttl);

	const DEFAULT_EXPIRES = expirationDate.toGMTString();

	const HOUR = 3600 * 1000;

	this.getDefault = () => {
		return DEFAULT_EXPIRES;
	};

	this.get = (pExpires) => {
		let expires = new Date();
		expires.setTime(expires.getTime() + pExpires);
		return expires.toGMTString();
	};

	this.getOneDayExpires = () => {
		return this.get(HOUR * 24);
	};
}
