import jCaptcha from 'js-captcha';
import $q from 'q'

/* @ngInject */
class CaptchaController {
    constructor (contestService, $timeout) {
        this.contestService = contestService;
        this.$timeout = $timeout;
        this.errorMessage = false;
    }

    $onInit = () => {
        this.contestService.validateCaptcha = this.validateCaptcha;
        this.initiateCaptcha();
        this.errorMessage = false;
        $('.captcha-canvas-wrapper input').attr('aria-label', this.captchaInputAriaLabel);
    }

    initiateCaptcha = () => {
        this.myCaptcha = new jCaptcha({
            el: '.jCaptcha',
            canvasClass: 'jCaptchaCanvas',
            requiredValue: "=",
            canvasStyle: {
                width: 100,
                height: 30,
                textBaseline: 'top',
                font: '15px Arial',
                textAlign: 'left',
                fillStyle: '#000'
            },
            // set callback function for success and error messages:
            callback: ( response, $captchaInputElement, numberOfTries ) => {
                this.defer = $q.defer();
                if ( response == 'success' ) {
                    // success handle, e.g. continue with form submit
                    this.defer.resolve(true);
                    this.$timeout(() => {
                        this.errorMessage = false;
                        $captchaInputElement.classList.add('captcha-success');
                    });
                }
                if ( response == 'error' ) {
                    // error handle, e.g. add error class to captcha input
                    this.defer.reject('wrong captcha');
                    this.$timeout(() => {
                        this.errorMessage = true;
                        $captchaInputElement.classList.remove('captcha-success');
                    });
                }
            }
        });
    }

    validateCaptcha = () => {
        this.myCaptcha.validate()
        return this.defer.promise;
    }
}

export default CaptchaController;
