/* @ngInject */
export default class CrmVehicleSelectorController {
    constructor($scope, $element, $timeout, fcaGeolocator) {
        this.$scope = $scope;
        this.$element = $element;
        this.$timeout = $timeout;
        this.fcaGeolocator = fcaGeolocator;
        this.selectedIndex = 0;
    }

    $onInit() {
        this.getSliderSettings();
        
        let LocationDidChangedEvent = this.fcaGeolocator.getLocationChangedEvent();
        this.$scope.$on(LocationDidChangedEvent, (_, data) => {
            this.$timeout(() => {
                $(this.$element).find('.vehicle-selector-header').slick('slickUnfilter');
                $(this.$element).find('.vehicle-selector-header').slick('slickFilter', '.filterd-slide');

                this.resetSelectedIndex();
            })
        });
    }

    getSliderSettings = () => {
        this.$timeout(() => {
            $(this.$element).find('.vehicle-selector-header').slick({
                lazyLoad: 'progressive',
                autoplay: false,
                dots: false,
                infinite: false,
                fade: false,
                slidesToShow: 7,
                slidesToScroll: 1,
                prevArrow: `<button class="slide-arrow prev-arrow" data-analyticsid="carousel-nav-prev" aria-label="${this.prevArrowLabel}"></button>`,
                nextArrow: `<button class="slide-arrow next-arrow" data-analyticsid="carousel-nav-next" aria-label="${this.nextArrowLabel}"></button>`,
                rows: 0,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 1,
                            infinite: false,
                            dots: false
                        }
                    },
                    {
                        breakpoint: 1124,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 950,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 690,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            touchMove: true
                        }
                    },
                    {
                        breakpoint: 510,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            touchMove: true
                        }
                    }
                ]
            });
            $(this.$element).find('.slider').slick({
                autoplay: false,
                dots: false,
                infinite: false,
                arrows: false,
                fade:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                rows: 0
            });

            $(this.$element).find('.vehicle-selector-header').slick('slickFilter', '.filterd-slide');
            this.resetSelectedIndex();
        });


    }

    changeBgImage = (image) => {
        if (this.$scope.style == null) return
        this.$scope.style.bg = {'background-image': `url(${image})`};
    }

    select = function(index, image) {
        $('.slider').slick('slickGoTo', index);
        this.changeBgImage(image);
    };

    containProvince = (provinces, province) => {
        return (provinces.indexOf(province) > -1);
    }

    resetSelectedIndex = () => {
        let elements = $(this.$element).find('.vehicle-selector-item');
        let selectedIndex = elements[0].getAttribute('data-order');
        $(this.$element).find('.slider').slick('slickGoTo', selectedIndex);
        this.selectedIndex = selectedIndex;
        let imageName = elements[0].getAttribute('data-image-name');
        this.changeBgImage(imageName);
    }
}
