import angular from 'angular';

import config from '../../config';
import extraBallotsController from './extra-ballots.controller'

export default angular
    .module(`${config.appName}.extra-ballot`, [])
    .directive("extraBallots", ExtraBallots)
    .name

function ExtraBallots() {
    return {
        restrict: 'A',
        scope: true,
        controllerAs: '$extraBallotsCtrl',
        controller: extraBallotsController
    };
}
