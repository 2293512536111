import angular from "angular";
import ng from 'angular';

import fcaGeolocator from './geolocator.service'

import template from './geolocator.html'

import './geolocator.scss'

export default angular
    .module('fca.geolocator.status', [fcaGeolocator])
    .component('fcaGeolocator', {
        controller: 'fcaGeolocatorComponentController',
        controllerAs: '$ctrl',
        template: template,
        bindings: {
            onLocationChange: '&'
        }
    }).controller('fcaGeolocatorComponentController', GeolocatorComponentController)
    .name;

/**
 * @ngdoc controller
 * @name fca.fcaGeolocator.controller:GeolocatorComponentController
 * @requires fca.fcaGeolocator.service:fcaGeolocator
 * @description [TODO]
 * @example
 * <pre>
 * [TODO]
 * </pre>
 */

function GeolocatorComponentController(fcaGeolocator, userLocation, $rootScope) {
    'ngInject';
    const LOCATION_BASE_FIELD = fcaGeolocator.getLocationBaseField();
    const LOCATION_BASE = fcaGeolocator.getLocationBase();
    const LOCATION_BASED_ON_BROWSER = fcaGeolocator.getLocationBasedOnBrowser();

    const LOCATION_UPDATE_METHOD = "locationUpdateMethod";
    const LOCATION_CITY_LABEL = "locationCityLabel";


    /**
     * @ngdoc property
     * @name location
     * @propertyOf fca.fcaGeolocator.controller:GeolocatorComponentController
     * @type {Object}
     * @description [TODO]
     */
    this.location = null;

    /**
     * @ngdoc property
     * @name apiUrl
     * @propertyOf fca.fcaGeolocator.controller:GeolocatorComponentController
     * @type {String}
     * @description Api url for autocomplete component
     */
    this.apiUrl = fcaGeolocator.getApiUrl('autoCompleteServiceUrl');

    this.isLoading = false;

    /**
     * @ngdoc method
     * @name detectCurrentPosition
     * @methodOf fca.fcaGeolocator.controller:GeolocatorComponentController
     * @param {Object} $evt [TODO]
     * @return {Object} [TODO]
     * @description [TODO]
     * @example
     * <pre>
     * [TODO]
     * </pre>
     */
    this.detectCurrentPosition = ($evt) => {
        $evt.preventDefault();
        this.isLoading = true;

        return fcaGeolocator.detectCurrentPosition().then((position) => {
            if (ng.isObject(position)) {
                fcaGeolocator.reverseGeocode(position).then((location) => {
                    this.isLoading = false;
                    this.locationChange(ng.extend(location, {
                        [LOCATION_BASE_FIELD]: LOCATION_BASED_ON_BROWSER,
                        [LOCATION_UPDATE_METHOD]: "detection"
                    }));
                });
            } else {
                // this happens mostly when the user refuses geo-location, fall back to ip
                fcaGeolocator.getCurrentLocation().then((location) => {
                    this.isLoading = false;
                    this.locationChange(ng.extend(location, {
                        [LOCATION_BASE_FIELD]: LOCATION_BASED_ON_BROWSER,
                        [LOCATION_UPDATE_METHOD]: "detection"
                    }));
                })
                    .catch((reason) => {
                        this.isLoading = false;
                        this.onLocationChange(null);
                    });
            }
        });
    };

    /**
     * @ngdoc method
     * @name setLocation
     * @methodOf fca.fcaGeolocator.controller:GeolocatorComponentController
     * @param {Object} data [TODO]
     * @description [TODO]
     * @example
     * <pre>
     * [TODO]
     * </pre>
     */
    this.setLocation = (data) => {
        if (data !== null) {
            let locationBase = LOCATION_BASE[1];
            if (fcaGeolocator.isFullLocationObject(data)) {
                this.locationChange(ng.extend(data, {
                    [LOCATION_BASE_FIELD]: locationBase,
                    [LOCATION_UPDATE_METHOD]: "city",
                    [LOCATION_CITY_LABEL]: data.name
                }));
            } else {
                fcaGeolocator.geocode(data).then((location) => {
                    this.location = ng.extend(location, {
                        [LOCATION_BASE_FIELD]: locationBase,
                        [LOCATION_UPDATE_METHOD]: "city",
                        [LOCATION_CITY_LABEL]: data.name
                    });
                    // Callback
                    this.locationChange(this.location);
                }, () => {
                    this.onLocationChange(null);
                });
            }
        }
    };

    this.setLocationWithPostalCode = (data) => {
        let locationBase = LOCATION_BASE[1];
        fcaGeolocator.geocodeWithPostalCode(data).then((location) => {
            this.location = ng.extend(location, {
                [LOCATION_BASE_FIELD]: locationBase,
                [LOCATION_UPDATE_METHOD]: "postalCode"
            });
            // Callback
            this.locationChange(this.location);
        }, () => {
            this.onLocationChange(null);
        });
    };

    /**
     * @ngdoc method
     * @name locationChange
     * @methodOf fca.fcaGeolocator.controller:GeolocatorComponentController
     * @param {Object} location [TODO]
     * @description [TODO]
     * @example
     * <pre>
     * [TODO]
     * </pre>
     */
    // This method is called for precise geolocation selection with city or postal code.
    this.locationChange = (location) => {
        // Create provinceObject for offers-region cookie set by userLocation.setLocation(location).
        var provinceObject = {'region': `${location.province},${location.region}`};
        $rootScope.$broadcast(fcaGeolocator.getLocationWillChangedEvent());
        // Set region/province in offers-region cookie.
        userLocation.setLocation(provinceObject);
        // Set location attributs in brd-location cookie.

        // condition to verify if we are in iframe mode

        $rootScope.$broadcast(fcaGeolocator.getLocationChangedEvent(), [location]);
        this.onLocationChange({
            location: location
        });
    };
}
