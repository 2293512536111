export default function FcaGeolocationDialogController($scope, $rootScope, ngDialog) {
	'ngInject';

	/**
	 * @ngdoc method
	 * @name close
	 * @methodOf fca.fcaGeolocator.controller:fcaGeolocationDialogController
	 * @description [TODO]
	 * @param  {Object} $evt [TODO]
	 * @example
	 * <pre>
	 * [TODO]
	 * </pre>
	 */

	$scope.close = ($evt) => {
		$rootScope.$broadcast('reset-scroll-on-close-dialog');
		$evt.preventDefault();
		/* Close modal without location setting */
		ngDialog.close(null, null);
	};

	/**
	 * @ngdoc method
	 * @name locationChange
	 * @methodOf fca.fcaGeolocator.controller:fcaGeolocationDialogController
	 * @description [TODO]
	 * @param  {Object} location [TODO]
	 * @example
	 * <pre>
	 * [TODO]
	 * </pre>
	 */
	$scope.locationChange = (location) => {
		/* Close modal with new location settings */
		ngDialog.close(null, location);
		return;
	};
}

