import angular from "angular";
import config from './../../config';
import ngCookies from 'angular-cookies'
import fcaGeolocator from './../../components/geolocator/geolocator.service'


export default angular
    .module('fca.userLocation', [ngCookies, fcaGeolocator])
    .service('userLocation', UserLocation)
    .name;

/**
 * This is responsible for the province/region dropdown.
 * It is also used to determine the user's position in a lot of templates.
 *
 * It expects a region to be composed of a province AND a region.
 * For example: QC,quebec
 *
 */
function UserLocation($rootScope, $window, $cookies, $http, $q, fcaGeolocator) {
    'ngInject';

    this.config = config;

    let {locationApiPath} = this.config.urls;
    let {expires, location: cookieName} = $window.FCA_SITES_CONFIG.cookies;

    let location;


    /**
     *
     * This should only be called when we don't have an actual location (when switching region with the pull down).
     * 1. set region/province in offers-region cookie
     * 2. Broadcast event which triggers the update of several components listening to the broadcast event
     * 3. Broadcast sets default city for that region/province in brd-location cookie
     *
     */
    this.changeLocation = (loc, forceLocation) => {
        if (angular.isObject(loc)) {
            // Set offers-region cookie
            if (forceLocation) {
                this.forceLocation(loc);
            } else {
                this.setLocation(loc);
            }

            if ((!dealerId && !hasHashParameter) || forceLocation) {
                // Update location
                location = angular.copy(loc);
                // Setting updated location only for brand
                let province = loc['region'].split(',')[0];
                // determine the default postal code for the province
                let postalCode = this.getDefaultPostalCodeForProvince(province);

                // Get a geocode object for that postal code, once done broadcast it
                fcaGeolocator.geocodeWithPostalCode(postalCode)
                    .then((location) => { // eslint-disable-line max-len
                        $rootScope.$broadcast(fcaGeolocator.getLocationChangedEvent(), [location]);
                    });
            }
        }

        return location;
    };

    // Return current stored location or null
    this.getLocation = () => {
        if (!location) {
            return this.initLocation();
        }

        return location;
    };

    this.getLocationLabel = () => {
        let loc = this.testEndOfGeolocation();
        let label = getLabel(loc.split(',')[0]);

        return label;
    };

    this.getLocationRegion = () => {
        let loc = this.testEndOfGeolocation();
        let region = loc.split(',')[1];

        return region;
    };

    this.getLocationProvince = () => {
        let loc = this.testEndOfGeolocation();
        let province = loc.split(',')[0];

        return province;
    };

    this.testEndOfGeolocation = () => {
        let loc = location;
        if (typeof loc != 'string') {
            loc = getDefaultLocation()['region'];
        }

        return loc;
    };

    this.initLocation = () => {
        let cookie = $cookies.get(cookieName);

        // check if already have a location
        if (cookie !== undefined) {
            if (!location) {
                location = angular.copy(cookie);
            }
            broadcastLocation(location);
        } else {
            // try to geolocate user
            location = $q.defer();

            navigator.geolocation.getCurrentPosition((position) => {
                location = $http.get(locationApiPath, {
                    method: 'GET',
                    params: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                }).then((response) => {
                    buildFromGeolocation(response.data.province);
                }, () => {
                    // api could not return location
                    let loc = getDefaultLocation();
                    this.setLocation(loc);
                });
            }, (error) => {
                // cannot geolocate
                let loc = getDefaultLocation();
                this.setLocation(loc);
            });

            return location.promise;
        }

        return location;
    };

    this.getDefaultPostalCodeForProvince = (province) => {
        /* eslint-disable complexity */
        let defaultLocations = config.defaultLocation;
        return  (defaultLocations[province] || defaultLocations['default']);

    };

    // make setLocation public
    this.setLocation = (loc) => {
        setLocation(loc);
    };

    this.forceLocation = (loc) => {
        setLocation(loc);
    };

    function setLocation(loc) {
        let value = loc['region'];
        // if we're actually working with a location, we need to create the region with province,region format
        if (value.indexOf(',') == -1 && loc.province) {
            value = loc.province + "," + getRegionByProvince(loc.province);
        }
        window.FcaCookieChecker.addCookie(cookieName, value, '/', expires, undefined);
        location = value;
        broadcastLocation(location);
    }

    function buildFromGeolocation(province) {
        let region = config.location[province]['region'];
        let locationObj = {'region': `${province},${region}`};

        setLocation(locationObj);
    }

    function getLabel(provinceCode) {
        let label = config.location[provinceCode]['desc'];

        return label;
    }

    function broadcastLocation(location) {
        let province = location.split(',')[0];
        let region = location.split(',')[1];
        let isValid = validateRequest(province);

        if (isValid) {
            $rootScope.$emit('regionalOffers.locationChange', location, province, region, getLabel(province)); // eslint-disable-line max-len
        } else {
            setLocation(getDefaultLocation());
        }
    }

    function validateRequest(province) {
        try {
           config.location[province]['desc'];
        } catch(error) {
            return false;
        }

        return true;
    }

    function getDefaultLocation() {
        // only need to return region property
        return {
            region: 'ON,ontario'
        };
    }

    // TODO: put regions per province directly in js
    function getRegionByProvince(province) {
        return config.location[province]['region'];
    }
}

