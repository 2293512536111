import angular from 'angular';
import config from '../../../config';
import PersonalizationTriggerController from './trigger.controller'

export default angular
    .module(`${config.appName}.trigger`, [])
    .directive('personalizationTrigger', PersonalizationTrigger)
    .name

function PersonalizationTrigger() {
    return {
        restrict: 'E',
        scope: true,
        bindToController: {
            idleDuration: '@',
            zoneName: '@',
            scenarioCode: '@'
        },
        controllerAs: '$ctrl',
        controller: PersonalizationTriggerController
    };
}



