import angular from 'angular';
import config from '../../config';

import template from './fca-legal-tooltip.html'
import Legalnotesontroller from './fca-legal-tooltip.controller'

import './_legal-notes.scss'

export default angular
	.module(`${config.appName}.legalnotes`, [])
	.controller('legalnotesController', ['$element', '$rootScope', '$scope', Legalnotesontroller])
	.component('fcaLegalTooltip', {
		scope: true,
		bindings: {
			label: '@',
			type: '@',
			value: '@?',
			key: '@',
			options: '<?',
			forcePlacement: '@',
			icon: '@',
			text: '@',
			color: '@',
			analyticsid: '@',
			analyticsvalue: '@'
		},
		transclude: true,
		template: template,
		controller: 'legalnotesController',
		controllerAs: '$ctrl'
	})
	.name
