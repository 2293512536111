import angular from "angular";
import $q from "q";

export default angular
    .module('fca.commonServices', [])
    .service('userPersistentIdService', UserPersistentIdService)
    .name;

function UserPersistentIdService($http) {
    const $service = this;
    this.basePersistentIdUrl = '/data/persistentId/email/';
    this.persistentId = "";

    $service.getPersistentId = (email, successCallback) => {
        let defer = $q.defer();
        let urlPersistentId = this.basePersistentIdUrl + email;
        let responseUid = "";
        let days = 365;

        $http.get(urlPersistentId)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    responseUid = response.data.uid;

                    // Always set the cookie
                    if (responseUid !=null && responseUid != "") {
                        this.setCookie("userId", responseUid, days);
                        defer.resolve(response.data);
                        if (successCallback) {
                            successCallback(responseUid);
                        }
                    }
                } else {
                    console.error("Error while getting user id for :" + email);
                }
            })
            .catch(function(e) {
                defer.reject('Error while getting persistent id', e);
            });

        return defer.promise;
    };

    $service.setCookie = (name, value, days) => {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
		window.FcaCookieChecker.addCookie(name, value, '/', expires, undefined);
    };
}
UserPersistentIdService.$inject = ['$http'];

