import angular from 'angular';
import config from '../config';

import vehicleSelector from './vehicle-selector/vehicle-selector.directive';
import contestForm from './contest-form/contest-form.directive';
import fcaModalWindow from './modal-window/fca-modal-window.directive'
import fcaModalContent from './modal-window/fca-modal-content.directive'
import fcaDialog from './dialogs/dialog.directive'
import fcaRegionSelector from './fca-region-selector/fca-region-selector.directive'
import fcaProvincialContent from './fca-provincial-content/fca-provincial-content.directive'
import extraBallotsController from './extra-ballots/extra-ballots.directive'
import fcaNameplateHighlights from './personalization/fill-the-funnel/fca-nameplate-highlights.directive'
import personalizationTrigger from './personalization/trigger/trigger.component'
import fcaVideo from './video/fca-video.directive'
import fcaVideoFactory from './video/fca-video.factory'

export default angular
    .module(`${config.appName}.directives`, [
        vehicleSelector,
        contestForm,
        fcaModalWindow,
        fcaModalContent,
        fcaDialog,
        fcaRegionSelector,
        fcaProvincialContent,
        extraBallotsController,
        fcaNameplateHighlights,
        personalizationTrigger,
        fcaVideo,
        fcaVideoFactory
    ]).name;
