import angular from "angular";
import config from '../../config';

class FunctionExecutionControl {
    debounce = (func, wait, immediate) => {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    throttle = (fn, wait) => {
        let time = Date.now();

        return function () {
            let newTime = Date.now();

            if ((time + wait - newTime) < 0) {
                fn();
                time = Date.now();
            }
        };
    };
}

export default angular.module(`${config.appName}.commonServices`, [])
    .service('functionExecutionControl', FunctionExecutionControl)
    .name



