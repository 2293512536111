export default {
    appName: 'fca.crm',
    autoCompleteServiceUrl: '/data/cities?search=',
    geoCodingServiceUrl: '/data/cities/location',
    getCurrentPositionUrl: '/api/location/current',
    defaultPostalCode: {
        'AB': 'T5B0B7',
        'BC': 'V5K1A1',
        'MB': 'R2X1L3',
        'NB': 'E3A0A1',
        'NL':' A1C1A1',
        'NT': 'X1A2R2',
        'NS': 'B3H1M3',
        'NU': 'X0A0H0',
        'ON': 'M4W1A1',
        'QC': 'H2Z1H2',
        'SK': 'S7J1E3',
        'YT': 'Y1A4M9',
        'deault':'M4W1A1'
    },
    defaultLocation: {
        latitude: "43.7068",
        longitude: "-79.3984",
        name: "TORONTO",
        postalCode: "M4R 1K8",
        province: "ON",
        reference: false,
        region: "ontario"
    },
    location: {
        AB: {
            desc: 'Alberta',
            region: 'alberta',
            regionDesc: 'Alberta'
        },
        BC: {
            desc: 'British Columbia',
            region: 'pacific',
            regionDesc: 'Pacific'
        },
        MB: {
            desc: 'Manitoba',
            region: 'prairies',
            regionDesc: 'Prairies'
        },
        NB: {
            desc: 'New Brunswick',
            region: 'atlantic',
            regionDesc: 'Atlantic'
        },
        NL: {
            desc: 'Newfoundland and Labrador',
            region: 'atlantic',
            regionDesc: 'Atlantic'
        },
        NU: {
            desc: 'Nunavut',
            region: 'alberta',
            regionDesc: 'Alberta'
        },
        NS: {
            desc: 'Nova Scotia',
            region: 'atlantic',
            regionDesc: 'Atlantic'
        },
        NT: {
            desc: 'Northwest Territories',
            region: 'pacific',
            regionDesc: 'Pacific'
        },
        ON: {
            desc: 'Ontario',
            region: 'ontario',
            regionDesc: 'Ontario'
        },
        QC: {
            desc: 'Quebec',
            region: 'quebec',
            regionDesc: 'Quebec'
        },
        SK: {
            desc: 'Saskatchewan',
            region: 'prairies',
            regionDesc: 'Prairies'
        },
        PE: {
            desc: 'Prince Edward Island',
            region: 'atlantic',
            regionDesc: 'Atlantic'
        },
        YT: {
            desc: 'Yukon',
            region: 'pacific',
            regionDesc: 'Pacific'
        }
    },
    urls: {
        locationApiPath: '/data/cities/location',
        campaignTrackerPath: '/momentum/campaign/campaignTracker.php',
        signUpPost: {
            en: '/en/subscribe',
            fr: '/fr/subscribe'
        },
        signUpThankYou: {
            en: '/en/thankyou',
            fr: '/fr/merci'
        },
        search: {
            en: '/en/search',
            fr: '/fr/recherche'
        },
        legal: {
            en: '/legacy/legal/quebec/en',
            fr: '/legacy/legal/quebec/fr',
            enError: 'No legal information currently available',
            frError: 'Aucune information légale disponible pour le moment'
        }
    },
    cookies: {
        location: 'offers_region',
        expires: new Date().toGMTString()
    },
    dealersWithChatCookieName: 'dealersWithChat',
    locationCookieName: 'brd-location',
    regionCookieName: 'brd-region',
    dealerEventContestSignUpPost: {
        en: '/en/dealer-event',
        fr: '/fr/dealer-event'
    },
    pca: {
        options: {
            key: 'fe29-nb36-pb18-dy78'
        }
    },
};
