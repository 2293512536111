import angular from 'angular';

import config from '../../config';
import contestFormController from './contest-form.controller'

import './contest-form.scss';

export default angular
    .module(`${config.appName}.contest-form`, [])
    .directive("contestForm", ContestForm)
    .name

function ContestForm() {
    return {
        restrict: 'A',
        scope: true,
        bindToController: {
            folderName: '@',
            eventCode: '@'
        },
        controllerAs: '$formCtrl',
        controller: ['$scope', 'contestService', '$timeout', '$location', 'googleTagManager', contestFormController]
    };
}
