import angular from 'angular';

import './_video.scss';

export default angular.module('fca.fcaVideo', [])
	.directive('fcaVideo', fcaVideo)
	.name

function fcaVideo() {
	return {
		restrict: 'A',
		scope: true,
		bindToController: {
			galleryItemId: '@',
			videoPath: '@',
			videoType: '@',
			fullscreenMode: '<',
			analyticsCategory: '@',
			analyticsId: '@'
		},
		controllerAs: 'video',
		controller: fcaVideoController
	};

	function fcaVideoController($scope, $rootScope, $element, $document, gtmAnalytics, fcaVideoFactory, AR_VLP_OVERLAY) {
		'ngInject';
		/* eslint-disable no-invalid-this */

		let vm = this;
		vm.isLoaded = false;
		vm.$content = $element.find('.video-content');
		vm.$container = $element.find('.video-content-container');
		vm.$disclaimer = $element.next('.feature-block-img-disclaimers');
		vm.$video;

		vm.$onInit = () => {
			$scope.$on('galleryPage.triggerActiveSlideContent', vm.triggerActiveSlideContent);
			$scope.$on('vlpGalleryOutOfVideo', vm.pauseYoutubeVideo);
			$rootScope.$on(AR_VLP_OVERLAY.CLOSE, vm.pauseYoutubeVideo);
			$scope.$on('vlpGalleryOnVideo', vm.startYoutubeVideo);
			$scope.$on('galleryPage.collapse', vm.destroyVideo);
		};
		vm.$onChanges = function(changes) {
			if (changes.fullscreenMode
				&& changes.fullscreenMode.currentValue != changes.fullscreenMode.previousValue) {
				if (vm.$container && vm.isLoaded) {
					if (vm.fullscreenMode) {
						vm.enterFullscreen();
					}else{
						vm.exitFullscreen();
					}
				}
			}
		};
		//broadcast coming from fca-hero-block-overlay.directive.js
		$scope.$on("hero-block-lightbox:destroyVideo",() => {
			vm.destroyVideo();
		});

		vm.loadVideo = function() {
			if (!vm.isLoaded) {
				if (vm.videoType == 'youtubes') {
					fcaVideoFactory.onAPIReady.then(() => {
						const newPlayer = document.createElement('div');
						newPlayer.className = "youtube-container";
						vm.$container.prepend(newPlayer);
						const videoUrl = vm.videoPath.split('/')[vm.videoPath.split('/').length - 1];
						vm.$video = new YT.Player(newPlayer, {
							height: '390',
							width: '640',
							host: 'https://www.youtube.com',
							videoId: videoUrl,
							playerVars: {
								rel: 0,
								showinfo: 0
							},
							events: {
								'onReady': () => {
									vm.$video.playVideo();
									vm.isLoaded = true;
									$scope.$apply(vm.$container.addClass('loaded'));
									//broadcast needed by fca-hero-block overlay
									//once video is loaded, create video closing button
									$rootScope.$broadcast('createClosingButton');
								},
							}
						});
					});
				} else if (vm.videoType == 'videos') {
					vm.$video = angular.element('<video src="' + vm.videoPath + '" width="100%" controls="" autoplay=""></video>'); // eslint-disable-line max-len
					vm.isLoaded = true;
					vm.$container.addClass('loaded');
					vm.$container.prepend(vm.$video);
				}

				if ($element.find('video').readyState >= 2) {
					$element.find('video').play();
					//broadcast needed by fca-hero-block overlay
					//once video is loaded, create video closing button
					$scope.$broadcast('createClosingButton');
				}
			}
			if (vm.fullscreenMode) {
				vm.enterFullscreen();
			}

			vm.sendGtmTrackEvent(vm.getVideoName() + ' - play');

			vm.$disclaimer.hide();
		};

		vm.destroyVideo = function() {
			if (!vm.isLoaded) return;
			if (vm.videoType == 'youtubes') {
				vm.$video.destroy();
			} else {
				vm.$video.remove();
			}
			vm.exitFullscreen();
			vm.isLoaded = false;
		};
		vm.enterFullscreen = function() {
			vm.$container.addClass('force-fullscreen');
			$document.get(0).body.append(vm.$container.get(0));
			vm.sendGtmTrackEvent(vm.getVideoName() + ' - enter fullscreen');
		};
		vm.exitFullscreen = function() {
			vm.$container.removeClass('force-fullscreen');
			vm.$content.append(vm.$container.get(0));
			vm.sendGtmTrackEvent(vm.getVideoName() + ' - exit fullscreen');
		};
		vm.triggerActiveSlideContent = function(event, galleryItemId) {
			if (vm.galleryItemId && galleryItemId != vm.galleryItemId) return;
			vm.loadVideo();
		};
		vm.sendGtmTrackEvent = (label) => {
			gtmAnalytics.trackEvent('event', {
				category: vm.getGategoryName(),
				label: label
			});
		};
		vm.getGategoryName = () => {
			let category = 'VLP';

			if ($('.mini-nav-sub-menu').length) {
				let name = $('.mini-nav-sub-menu').find('a.is-active').text().trim();
				category = 'VLP ' + name;
			}

			return category;
		};
		vm.getVideoName = () => {
			let deconstructedPath = vm.videoPath.split('/');
			let videoName = deconstructedPath[deconstructedPath.length - 1];

			return videoName;
		};
		vm.pauseYoutubeVideo = () => {
			if(vm.isLoaded && vm.$video.pauseVideo) {
				vm.$video.pauseVideo();
			}
		}
		vm.startYoutubeVideo = (evt, url) => {
			const currentVideoPath = $element[0].dataset.videoPath;
			if (currentVideoPath === url) {
				if (vm.isLoaded) {
					vm.$video.playVideo();
				} else {
					vm.loadVideo();
				}
			}
		}
	}
}
