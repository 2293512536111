import angular from "angular";
import config from '../../config';

function functionDynamicSizing () {
    'ngInject';

    let $service = this;

    $service.equalHeight = (elements) => {
        if (elements && elements.length > 1) {
            // reset to auto
            elements.css('height', 'auto');

            let largerHeight = 0;

            elements.each(function() {
                if (this.offsetHeight > largerHeight) {
                    largerHeight = this.offsetHeight;
                }
            });

            if (largerHeight > 0) {
                // output larger height to all elements
                elements.css('height', largerHeight + 'px');
            }
        }
    };
}

export default angular.module(`${config.appName}.functionDynamicSizing`, [])
    .service('functionDynamicSizing', functionDynamicSizing)
    .name
