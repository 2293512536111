import angular from "angular";

export default angular
    .module('fca.commonServices', [])
    .service('storageService', StorageService)
    .name;

function StorageService() {

    const $service = this;

    $service.isCookiesEnabled = () => {
        return navigator.cookieEnabled;
    };

    $service.sessionStorageGetItem = sessionItem => {
        let item;
        if ($service.isCookiesEnabled()) {
            item = sessionStorage.getItem(sessionItem);
        } else {
            item = null;
        }
        return item;
    };
}

