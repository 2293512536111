import angular from 'angular'
import config from './../config'
import $q from "q";

class ContestService {
    static $inject = ['$http', '$location', '$timeout', 'userPersistentIdService']
    constructor($http, $location, $timeout, userPersistentIdService) {
        this.$service = this;
        this.$http = $http;
        this.$location = $location;
        this.$timeout = $timeout;
        this.validateUserInfomations = null;
        this.validateCaptcha = null;
        this.formSubmited = null;
        this.formIsInvalid = null;
        this.userInformation = {};
        this.extraBallotRequired = null;
        this.folderName = null;
        this.eventCode = null;
        this.userPersistentIdService = userPersistentIdService;
        this.persistentID = "";
        this.isRequestSent = false;
    }

    submit = (folderName, eventCode) => {
        this.$service.validateUserInfomations()
            .then((results) => {
                if (this.$service.validateCaptcha) {
                    return this.$service.validateCaptcha()
                }
                return true;
            })
            .then((results) => {
                if (this.$service.userInformation.email != null && this.$service.userInformation.email != "") {
                    return this.userPersistentIdService.getPersistentId(this.$service.userInformation.email,
                        this.$service.setPersistentId)
                }
                return true;
            })
            .then((results) => {
                return this.$service.sendRequest(folderName, eventCode)
            })
            .then((response) => {
                this.$timeout(() => {
                    this.$service.formSubmited();
                })
            })
            .catch(e => {
                this.$service.formIsInvalid();
                console.error(e);
            });
    }

    /**
     * Success Call Back when the persistent ID is correctly received
     **/
    setPersistentId = (id) => {
        this.$service.persistentID = id;
    }

    /**
     * Checks if one contact method at least has been entered
     **/
    existsContactMethod = () => {
        let email = this.$service.userInformation.email;
        let address = this.$service.userInformation.address;
        let city = this.$service.userInformation.city;
        let province  = this.$service.userInformation.province;
        let exists = (email != "" || (address != "" && city != "" && province != "")) ? true : false;

        return exists;

    }

    sendRequest = (folderName, eventCode) => {
        const utmParameters = this.$location.search();
        const currentLocale = window.FCA_SITES_CONFIG.language;
        let url = "https://" + this.$location.host() + '/' + eventCode + config.dealerEventContestSignUpPost[currentLocale];
        let srcSrcValue = "";
        if (utmParameters.srcSrc != null) {
            srcSrcValue = (Array.isArray(utmParameters.srcSrc) && utmParameters.srcSrc.length > 0)?utmParameters.srcSrc[0]:utmParameters.srcSrc;
        }

        let formData = {
            agree: this.userInformation.checkboxAgreeConditions,
            optIn: this.$service.userInformation.optIn,
            firstName: this.$service.userInformation.firstName,
            lastName: this.$service.userInformation.lastName,
            address: this.$service.userInformation.address,
            city: this.$service.userInformation.city,
            province: this.$service.userInformation.province,
            phoneNumber: this.$service.userInformation.phoneNumber,
            email: this.$service.userInformation.email,
            postalCode: this.$service.userInformation.postalCode,
            language: window.FCA_SITES_CONFIG.language,
            nameplateCodes: this.$service.userInformation.multipleVois,
            dealerCode: this.$service.userInformation.dealerCode != null ? this.$service.userInformation.dealerCode : "",
            brandGroup: this.$service.userInformation.brandGroup,
            whenPurchase: this.$service.userInformation.purchaseIntent,
            folder: folderName,
            persistentID: this.$service.persistentID,
            contactMethodChosen : this.$service.existsContactMethod(),
            utmCampaign: utmParameters.utm_campaign != null ? utmParameters.utm_campaign : "",
            utmSource: utmParameters.utm_source != null ? utmParameters.utm_source : "",
            utmMedium: utmParameters.utm_medium != null ? utmParameters.utm_medium : "",
            utmContent: utmParameters.utm_content != null ? utmParameters.utm_content : "",
            srcSrc: srcSrcValue,
            refSrc: utmParameters.refSrc != null ? utmParameters.refSrc : ""
        }

        let defer = $q.defer();

        this.$http.post(url,formData)
            .then((response) => {
                defer.resolve(response.data);
            }).catch(function(e) {
            defer.reject('error sending contest', e);
        });

        return defer.promise;
    }
}

export default angular.module('services.contest', [])
    .service('contestService', ContestService)
    .name
