import angular from 'angular';

import template from './fca-modal-content.html';

export default angular.module('fca.modal', [])
    .directive('fcaModalContent', fcaModalContent)
    .name

function fcaModalContent() {
    return {
        restrict: 'E',
        scope: true,
        transclude: true,
        template: template,
        controllerAs: '$fcaModalContentCtrl',
        controller: FcaModalContentController
    };
    function FcaModalContentController($scope) {
        'ngInject';
        let $ctrl = this;
        $ctrl.modalId = $scope.$parent.modalWindow.modalId;
    }
}

