import angular from 'angular';

import template from './select-dealer-dropdown.html'
import './select-dealer-dropdown.scss'
import SelectDealerDropdowncontroller from './select-dealer-dropdown.controller'


export default angular
    .module('ui.select', [])
    .controller('selectDealerDropdown', SelectDealerDropdowncontroller)
    .component('selectDealerDropdown', {
        template: template,
        controller: 'selectDealerDropdown',
        controllerAs: '$ctrl',
        bindings: {
            location: '<',
            currentUrl: '@',
            dealersRadius: '@',
            selectedBrand: '<',
            selectedDealerValue: '&',
            postalCodeValue: '@'
        }
    })
    .name;
