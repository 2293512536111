import angular from 'angular'
import ng from "angular"
import config from '../../../config';

export default angular.module(`${config.appName}.geolocator-auto-detection`, [])
	.service('geolocatorAutoDetection', GeolocatorAutoDetection)
	.name

function GeolocatorAutoDetection(
	geolocatorCookie,
	geolocatorCookieExpire,
	cookieLocation,
	fcaGeolocator,
	$rootScope) {
	'ngInject';

	const LOCATION_BASED_ON_BROWSER = fcaGeolocator.getLocationBasedOnBrowser();
	const LOCATION_BASE_FIELD = fcaGeolocator.getLocationBaseField();
	const LOCATION_CHANGED_EVENT = fcaGeolocator.getLocationChangedEvent();
	const RADIUS = 20;
	const DEFAULT_LOCATION = 'toronto';

	this.detect = (cb) => {
		if (this.isAutoDetectionMandatory()) {
			fcaGeolocator.detectCurrentPosition().then((position) => {
				if (ng.isObject(position)) {
					fcaGeolocator.reverseGeocode(position).then((location) => {
						let broadcast = true;

						if (location.name.toLowerCase() === DEFAULT_LOCATION) {
							// Broadcast new detect location only
							// If location server match with browser detection
							// Case : user outside canada or error server IP detection
							broadcast = fcaGeolocator.isInRadius(position, location, RADIUS);
						}


						if (broadcast) {
							ng.extend(location, {
								[LOCATION_BASE_FIELD]: LOCATION_BASED_ON_BROWSER
							});

							$rootScope.$broadcast(LOCATION_CHANGED_EVENT, [location]);
						}

						if( typeof(cb) === typeof(Function)) {
							cb(location);
						}
					});
				} else {
					// this happens mostly when the user refuses geo-location, fall back to ip
					fcaGeolocator.getCurrentLocation().then((location) => {
						ng.extend(location, {
							[LOCATION_BASE_FIELD]: LOCATION_BASED_ON_BROWSER
						});
						$rootScope.$broadcast(LOCATION_CHANGED_EVENT, [location]);

						if( typeof(cb) === typeof(Function)) {
							cb(location);
						}
					});
				}
			});
		}
	};

	this.isAutoDetectionMandatory = () => {
		let isMandatory = true;
		// Location exists
		if (cookieLocation.isCookieExists()) {
			isMandatory = false;
		}
		return isMandatory;
	};
}

