/* @ngInject */
export default class Dialog {
    constructor(dialogEl, overlayEl = null) {
        this.dialogEl = dialogEl;
        if (overlayEl != null) this.overlayEl = overlayEl;

        this.refreshOnTab = false;
        this.focusedElBeforeOpen;
        this.closeKeyboardCallback;

        let focusableEls = $(this.dialogEl).find('a[href]:not(.ng-hide), area[href]:not(.ng-hide), input:not([disabled]):not(.ng-hide), select:not([disabled]):not(.ng-hide), textarea:not([disabled]):not(.ng-hide), button:not([disabled]):not(.ng-hide), video:not(.ng-hide), [tabindex="0"]:not(.ng-hide)').filter(':visible');
        this.focusableEls = Array.prototype.slice.call(focusableEls);

        this.firstFocusableEl = this.focusableEls[0];
        this.lastFocusableEl = this.focusableEls[ this.focusableEls.length - 1 ];
        this.close(); // Reset
    }

    open = () => {
        let Dialog = this;

        this.focusedElBeforeOpen = document.activeElement;

        this.dialogEl.addEventListener('keydown', function(e) {
            Dialog._handleKeyDown(e);
        });

        if (this.overlayEl != null) {
            this.overlayEl.addEventListener('click', () => {
                Dialog.close();
            });
        }

        this.firstFocusableEl.focus();
    };

    resetInitialFocusedEl = target => this.focusedElBeforeOpen = target;

    refresh = () => {
        let focusableEls = $(this.dialogEl).find('a[href]:not(.ng-hide), area[href]:not(.ng-hide), input:not([disabled]):not(.ng-hide), select:not([disabled]):not(.ng-hide), textarea:not([disabled]):not(.ng-hide), button:not([disabled]):not(.ng-hide), video:not(.ng-hide), [tabindex="0"]:not(.ng-hide)').filter(':visible');
        this.focusableEls = Array.prototype.slice.call(focusableEls);

        this.firstFocusableEl = this.focusableEls[0];
        this.lastFocusableEl = this.focusableEls[ this.focusableEls.length - 1 ];
    };

    close = () => {
        if (this.focusedElBeforeOpen) this.focusedElBeforeOpen.focus();
    };

    setRefreshOnTab = boolean => (this.refreshOnTab !== boolean) ? this.refreshOnTab = boolean : this.refreshOnTab;

    _handleKeyDown = e => {
        let Dialog = this;
        let KEY_TAB = 9;
        let KEY_ESC = 27;

        function handleBackwardTab() {
            if ( document.activeElement === Dialog.firstFocusableEl ) {
                e.preventDefault();
                Dialog.lastFocusableEl.focus();
            }
        }
        function handleForwardTab() {
            if ( document.activeElement === Dialog.lastFocusableEl ) {
                e.preventDefault();
                Dialog.firstFocusableEl.focus();
            }
        }

        switch(e.keyCode) {
            case KEY_TAB:
                if(this.refreshOnTab) {
                    Dialog.refresh();
                }

                if ( Dialog.focusableEls.length === 1 ) {
                    e.preventDefault();
                    break;
                }
                if ( e.shiftKey ) {
                    handleBackwardTab();
                } else {
                    handleForwardTab();
                }
                break;
            case KEY_ESC:
                Dialog.close();
                if (this.closeKeyboardCallback) {
                    this.closeKeyboardCallback.call();
                }
                break;
            default:
                break;
        }
    };

    addEventListeners = (openDialogSel, closeDialogSel, closeKeyboardCallback) => {
        let Dialog = this;

        Dialog.closeKeyboardCallback = closeKeyboardCallback;
        let openDialogEls = document.querySelectorAll(openDialogSel);
        for ( var i = 0; i < openDialogEls.length; i++ ) {
            openDialogEls[i].addEventListener('click', function() {
                Dialog.open();
            });
        }

        let closeDialogEls = document.querySelectorAll(closeDialogSel);
        for ( let i = 0; i < closeDialogEls.length; i++ ) {
            closeDialogEls[i].addEventListener('click', function() {
                Dialog.close();
            });
        }
    };
}
