export default class SelectDealerDropdownController {
    constructor($scope, $http, $window, $timeout, contestService, googleTagManager) {
        'ngInject';

        this.dealerDropdownOptions = [];
        this.selectedDealer = "";
        this.regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        this.$http = $http;
        this.$timeout = $timeout;
        this.contestService = contestService;
        this.toggleOpen = false;
        this.focussedElement;
        this.nextFocusableElementFromInput;
        this.googleTagManager = googleTagManager;
        let _this = this;

        angular.element($window).on('click', function (event) {
            $timeout(() => {
                _this.toggleOpen = false;
            })
        });

        $scope.$watch(() => {
                return this.selectedBrand; // detect this.selectedBrand changes
            },
            (newVal, oldVal) => {
                if (!this.sameBrandGroup(newVal, oldVal)) {
                    this.selectedDealer = null
                    this.contestService.userInformation.dealerCode = "";
                    this.contestService.userInformation.brandGroup = "";
                    if (this.postalCodeValue != null) { // prevent from getting dealer with no postalcode
                        this.validatePostalCode(this.postalCodeValue);
                    }
                }
            });
    }

    $onInit() {
        this.selectedBrand = null ? this.selectedBrand : 'jeep';
        this.defineNextInputFocusableElement();
    }

    toggleDropdown = () => {
        this.toggleOpen = !this.toggleOpen;

        if (this.toggleOpen === true) {
            this.validatePostalCode(this.postalCodeValue);
            this.enableEscPress();
            this.$timeout(() => {
                this.handlePostalCodeBlur();
            });
        } else {
            this.removeEscPress();
        }
    }

    manageEscPress = (e) => {
        if (e.key === "Escape" || e.code === "Escape") {

            this.$timeout(() => {
                this.toggleDropdown();
            });
        }
    }

    enableEscPress = () => {
        this.focussedElement = $(document.activeElement);
        document.addEventListener('keydown', this.manageEscPress);
    }

    removeEscPress = () => {
        document.removeEventListener('keydown', this.manageEscPress);
        this.focussedElement.focus();
    }

    enterPostalCode = e => this.validatePostalCode(e.currentTarget.value);

    validatePostalCode = (postalCode) => {
        this.isValid = this.regex.exec(postalCode);

        if (!this.isValid && postalCode.length >= 6) this.displayError = true;

        if (this.isValid) {
            this.displayError = false;
            // change the ng-value to new valid postalCode
            this.postalCodeValue = postalCode;
            this.getDealers(postalCode);
        }
    }

    stopPropagation = $event => $event.stopPropagation();

    getDealers = postalCode => {
        let url = ' /data/dealers/closest-by-postal-code?postalCode='
            + postalCode
            + '&minResults=1&maxResults=7'
            + '&brand=' + this.selectedBrand;

        this.$http.get(url)
            .then((response) => {
                this.dealerDropdownOptions = response.data.dealers;
            });
    }

    selectDealer = dealer => {
        this.selectedDealer = dealer;
        this.contestService.userInformation.dealerCode = dealer != null ? dealer.code : "";
        this.contestService.userInformation.brandGroup = this.getCurrentBrandGroup(this.selectedBrand);

        this.googleTagManager.push({ event: 'gtmCustomEvent', gtmCustomEvent: `Contest-Form-Dealer-Select - ${dealer.name}` });
    }

    defineNextInputFocusableElement = () => {
        const focusableElements = $(document).find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])')
        let inputElement = focusableElements.index($('.crm-ui-select-label'));
        this.nextFocusableElementFromInput = focusableElements[inputElement + 1];
    }

    handlePostalCodeBlur = () => {
        document.querySelector('.crm-ui-select-list input').addEventListener('keydown', this.managePostalCodeTab);
    }

    managePostalCodeTab = (e) => {
        if ((e.key === 'Tab' || e.code === 'Tab') && !e.shiftKey) {
            this.toggleDropdown();

            this.$timeout(() => {
                this.nextFocusableElementFromInput.focus();
                document.querySelector('.crm-ui-select-list input').removeEventListener('keydown', this.managePostalCodeTab);
            });
        }
    }

    sameBrandGroup = (brandA, brandB) => {
        return this.getCurrentBrandGroup(brandA) == this.getCurrentBrandGroup(brandB)
    }

    getCurrentBrandGroup = (brand) => {
        switch(brand) {
            case 'jeep': case 'ramtruck': case 'dodge': case 'chrysler': return "CD"
            case 'fiat':  return "FI"
            case 'alfaromeo':  return "AL"
        }
    }
}
