import angular from "angular";
import $q from "q";

export default angular
    .module('fca.videoFactory', [])
    .factory('fcaVideoFactory', fcaVideoFactory)
    .name;

function fcaVideoFactory($q, $window) {
    'ngInject';
    const deferred = $q.defer();
    const apiReady = deferred.promise;

    const getAPIScript = document.querySelector(`script[src="https://www.youtube.com/iframe_api"]`);

    if(!$window.onYouTubeIframeAPIReady && !getAPIScript) {
        const youtubeAPI ="https://www.youtube.com/iframe_api";
        const tag = document.createElement('script');
        const scriptTag = document.getElementsByTagName('script')[0];
        
        tag.src = youtubeAPI;
        scriptTag.parentNode.insertBefore(tag, scriptTag);
    }

    $window.onYouTubeIframeAPIReady = () => {
        deferred.resolve();
    }

    return {
        onAPIReady: apiReady
    }   
}
