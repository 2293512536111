import angular from 'angular';

import "angular-translate";
import "angular-translate/dist/angular-translate-loader-url/angular-translate-loader-url.min";
import ngMessages from "angular-messages";
import ngSanitize from "angular-sanitize"
import ngBindHtmlCompile from "angular-bind-html-compile"

import config from './config';

import directives from './directives/directives.module';
import components from './components/components.module';
import layoutConstants from './layout.constants'
import fcaGeolocator from './components/geolocator/geolocator.service'
import contestService from './services/contest.service';
import commonFilter from './filters/htmlToPlainText.filter'
import googleTagManager from './services/analytics/analytics.service'

import userLocation from './services/user-location/user-location.service'
import cookieLocation from './services/geolocator-cookie/geolocator.cookie.location.service'
import geolocatorCookie from './services/geolocator-cookie/geolocator.cookie.factory'
import geolocatorCookieExpire from './services/geolocator-cookie/geolocator.cookie.expires.service'
import dealerChatService from './services/dealer-chat/dealer-chat.service'
import geolocatorAutoDetection from './components/geolocator/geolocator-auto-detection/geolocator-auto-detection.service'
import userPersistentIdService from './services/user-persistent-id.service'
import partialService from './services/partial/partial.service'
import functionExecutionControl from './services/function-execution-control/function-execution-control.service'
import functionDynamicSizing from './services/function-dynamic-sizing/function-dynamic-sizing.service'

import 'lazysizes';
import 'angular-base64'

import '../styles/app.scss'

angular
    .module(config.appName, [functionExecutionControl, directives, components, fcaGeolocator, contestService, commonFilter, cookieLocation, geolocatorCookie, geolocatorCookieExpire, dealerChatService, userLocation, geolocatorAutoDetection, googleTagManager, userPersistentIdService, partialService, functionDynamicSizing, ngMessages, ngSanitize, ngBindHtmlCompile])
    .constant('FCA_MQ_PICTURE', layoutConstants)
    .config(configTranslateProvider)
    .config(locationProvider)
    .controller('AppController',
        function($rootScope, $scope, FCA_MQ_PICTURE, fcaGeolocator, $anchorScroll, cookieLocation, userLocation, geolocatorAutoDetection, $location) {
            'ngInject'

            $rootScope.FCA_MQ_PICTURE = FCA_MQ_PICTURE;

            /**
             * @ngdoc property
             * @name location
             * @description User location object
             * @type {Object}
             */
            this.location = null;
            this.selectedBrand = null;
            this.utmParameters = "";

            $scope.style = {};
            $scope.style.bg = {'background-image': 'url()'}

            geolocatorAutoDetection.detect(function(location) {
                // setting the user location is done directly in the callback because the user location emits a change location event
                userLocation.setLocation(location);
            });

            userLocation.initLocation();

            /* eslint-disable prefer-spread */
            if (cookieLocation.isCookieExists()) {
                this.location = cookieLocation.getLocation();
            }

            // if we don't have a location, use the default one until we geo-locate the user
            if (this.location == null) {
                this.location = fcaGeolocator.getDefaultLocation();
            }

            let url = $location.url();
            if (url.indexOf("?") > -1) {
                this.utmParameters = '?' + url.substring(url.indexOf("?")+1);
            }

            // add data-attribute on the body to get access to the location name
            $('body').attr('data-location-province', this.location.province);

            $scope.goToAnchor = function(anchor){
                $anchorScroll(anchor);
            };

            this.stopPropagation = ($event) => {
                $event.stopPropagation();
            }

            const eventChangeName = fcaGeolocator.getLocationChangedEvent();
            $scope.$on(eventChangeName, (evt, args) => {
                // Location
                let [location] = args;

                $('.js-vehiclerecommender').trigger({
                    type: 'vehicle-recommender-event:location-change',
                    lat: args[0].latitude,
                    long: args[0].longitude,
                    postalCode: args[0].postalCode,
                });

                this.location = location;

                // Set new location in cookie
                cookieLocation.setLocation(location);

                //  Update location on all location status component
                let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
                $scope.$broadcast(eventUpdateName, [location]);
                $('body').attr('data-location-province', this.location.province);
            });
        })

function configTranslateProvider($translateProvider) {
    'ngInject';

    $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

    // https://angular-translate.github.io/docs/#/guide/12_asynchronous-loading
    $translateProvider.useUrlLoader(window.FCA_SITES_CONFIG.messagesPath, {});

    $translateProvider.preferredLanguage('en');

    $translateProvider.preferredLanguage(window.FCA_SITES_CONFIG.preferredLanguage);
}


function locationProvider($locationProvider) {
    'ngInject';
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });
}
