import angular from "angular";
import ng from "angular";

import config from './../../config';

export default angular
	.module('fca.geolocator.service', [])
	.provider('fcaGeolocator', fcaGeolocatorProvider)
	.name;

/**
 * @ngdoc service
 * @name fca.geolocator.provider:geolocatorProvider
 * @description [TODO]
 * @example
 * <pre>
 * [TODO]
 * </pre>
 */
function fcaGeolocatorProvider() {
	this.config = config;


	/**
	 * @ngdoc property
	 * @name defaults
	 * @propertyOf fca.geolocator.provider:geolocatorProvider
	 * @description Defaults configuration for geocoding services
	 * @type {Object}
	 */
	this.defaults = {
		apiUrl: '/',
		autoCompleteServiceUrl: this.config.autoCompleteServiceUrl,
		geoCodingServiceUrl: this.config.geoCodingServiceUrl,
		getCurrentPositionUrl: this.config.getCurrentPositionUrl
	};

	/**
	 * @ngdoc method
	 * @name use
	 * @propertyOf fca.geolocator.provider:geolocatorProvider
	 * @description Setter for configurations properties
	 * @param {Object} options Configuration object
	 */
	this.use = (options) => {
		this.defaults = ng.extend({}, this.defaults, options);
		return this;
	};

	// Return service
	this.$get = Service;
}

function Service(
	$window,
	$log,
	$q,
	$http,
	$location,
	cookieLocation,
	$filter) {
	'ngInject';

	// Return service instance
	return new GeocoderServiceFactory(
		this.defaults,
		$window,
		$log,
		$q,
		$http,
		$location,
		cookieLocation,
		$filter);

	/**
	 * @ngdoc service
	 * @name fca.geolocator.service:fcaGeolocator
	 * @requires $window
	 * @requires $log
	 * @requires $q
	 * @requires $http
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	function GeocoderServiceFactory(
		factoryCfgs,
		$window,
		$log,
		$q,
		$http,
		$location,
		cookieLocation,
		$filter) {
		'ngInject';

		const LOCATION_BASE_FIELD = 'locationBaseOn';
		const LOCATION_BASE = ['browser', 'userInput'];
		const _distance = $filter('filter');

		return {
			getConfig: getConfig,
			getApiUrl: getApiUrl,
			isSupported: isSupported,
			detectCurrentPosition: detectCurrentPosition,
			reverseGeocode: reverseGeocode,
			geocode: geocode,
			geocodeWithPostalCode: geocodeWithPostalCode,
			getCurrentLocation: getCurrentLocation,
			getDefaultLocation: getDefaultLocation,
			getLocationWithReverseGeocoding: getLocationWithReverseGeocoding,
			isFullLocationObject: isFullLocationObject,
			// TODO: either properly document the need for having both events or remove one
			getLocationWillChangedEvent: function() {
				return 'EVENT.geolocation.locationWillChange';
			},
			getLocationChangedEvent: function() {
				return 'EVENT.geolocation.locationChanged';
			},
			getLocationUpdatedEvent: function() {
				return 'EVENT.geolocation.locationUpdated';
			},
			getLocationBaseField: function() {
				return LOCATION_BASE_FIELD;
			},
			getLocationBase: function() {
				return LOCATION_BASE;
			},
			getLocationBasedOnBrowser: function() {
				return LOCATION_BASE[0];
			},
			getLocationBasedOnUserInput: function() {
				return LOCATION_BASE[1];
			},
			isLocationBaseOnClientSide: function(location) {
				let isClientSide = false;
				if (ng.isObject(location) && location.hasOwnProperty(LOCATION_BASE_FIELD)) {
					let locationBaseOn = location[LOCATION_BASE_FIELD];
					isClientSide = (LOCATION_BASE.indexOf(locationBaseOn) !== -1);
				}

				return isClientSide;
			},
			isInRadius: function(location, reference, radius) {
				let distance = _distance(location, reference);
				let inRadius = true;

				if (distance > radius) {
					inRadius = false;
				}

				return inRadius;
			}
		};

		/**
		 * @ngdoc method
		 * @name getConfig
		 * @methodOf fca.geolocator.service:fcaGeolocator
		 * @return {Object} Configuration service object
		 */
		function getConfig() {
			return factoryCfgs;
		}

		/**
		 * @ngdoc method
		 * @name getApiUrl
		 * @methodOf fca.geolocator.service:fcaGeolocator
		 * @param {String} token Property name to retrieve in configuration
		 * @return {String} Url for geocoding or autocomplete service
		 */
		function getApiUrl(token) {
			// let uri = (factoryCfgs.apiUrl || '').replace(/\/$/, '');
			let url = `${factoryCfgs[token]}`;
			return url;
		}

		/**
		 * @ngdoc method
		 * @name isSupported
		 * @methodOf fca.geolocator.service:fcaGeolocator
		 * @return {Boolean} Return true if the geolocation is supported by the browser
		 */
		function isSupported() {
			let supported = false;

			if (navigator.geolocation) {
				supported = true;
			}

			return supported;
		}

		/**
		 * @ngdoc method
		 * @name detectCurrentPosition
		 * @methodOf fca.geolocator.service:fcaGeolocator
		 * @return {Object} Return angular promise and position object (latitude, longitude) for reverse geocoding
		 */
		function detectCurrentPosition() {
			let defer = $q.defer();

			if (!isSupported()) {
				$log.warn('fcaGolocator service exception: geolocation is not supported !');
				/* Fail promise */
				defer.reject(null);
			}

			//check geolocation state if it's still in prompt
			navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
				if (permissionStatus.state === 'prompt') {
					defer.resolve(null);
				}
			});

			/* Get location from the browser's geo-location */
			navigator.geolocation.getCurrentPosition((position) => {
				browserLocationReceived(position);
				defer.resolve(position.coords);
			}, () => {
				defer.resolve(null);
			});

			return defer.promise;
		}

		function browserLocationReceived(position) {
			// add GTM tag
		}

		function getDefaultLocation() {
			return config.defaultLocation;
		}

		/**
		 * @ngdoc method
		 * @name getCurrentLocation
		 * @methodOf fca.geolocator.service:geolocator
		 * @description Retrieve location server information detection
		 * @return {Object} Return angular promise and location object on resolved
		 */
		function getCurrentLocation() {
			let url = getApiUrl('getCurrentPositionUrl');
			return $http.get(url).then((r) => {
				let locationData = r.data;
				return locationData;
			});
		}

		/**
		 * @ngdoc method
		 * @name reverseGeocode
		 * @methodOf fca.geolocator.service:fcaGeolocator
		 * @description Retrieve location city based on latitude/longitude information
		 * @return {Object} Return angular promise and city object on resolved
		 */
		function reverseGeocode(position) {
			let url = getApiUrl('geoCodingServiceUrl');
			let lat = position.latitude;
			let lng = position.longitude;

			return $http.get(`${url}?latitude=${lat}&longitude=${lng}`).then((r) => {
				return r.data;
			});
		}

		/**
		 * @ngdoc method
		 * @name geocode
		 * @methodOf fca.geolocator.service:fcaGeolocator
		 * @description Retrieve location cities group based on name information
		 * @return {Object} Return angular promise and cities array object on resolved
		 */
		function geocode(location) {
			let url = getApiUrl('geoCodingServiceUrl');
			// Default values if nothing is founded
			let params = 'city=Toronto&province=ON';
			// Force return null
			let forced;
			// Override if an object exists
			if (ng.isObject(location)) {
				params = `city=${location.name}&province=${location.province}`;
			} else {
				forced = null;
			}

			return $http.get(`${url}?${params}`).then((r) => {
				return (forced === null) ? forced : r.data;
			});
		}

		function geocodeWithPostalCode(postalCode) {
			let url = getApiUrl('geoCodingServiceUrl');
			let params = `postalCode=${postalCode}`;
			return $http.get(`${url}?${params}`).then((r) => {
				return r.data;
			});
		}

		function getLocationWithReverseGeocoding() {
			let params = $location.search();
			let {latitude, longitude} = params;
			if (latitude && longitude) {
				// Resolve on geolocation returned by service
				return reverseGeocode(params);
			}

			let {
				postalCode
			} = params;
			postalCode = decodeURIComponent(postalCode).replace(/[^A-Za-z0-9]/ig, '');
			if (postalCode.length === 6) {
				return geocodeWithPostalCode(postalCode);
			}

			// // use cookie if exists
			if (cookieLocation.isCookieExists()) {
				let defer = $q.defer();
				// Resolve on cookie value
				defer.resolve(cookieLocation.getLocation());
				return defer.promise;
			}

			// Use service by default
			return getCurrentLocation().then((location) => {
				// Set location in cookie
				cookieLocation.setLocation(location);
				return location;
			});
		}

		function isFullLocationObject(location) {
			let props = [
				'name',
				'province',
				'postalCode',
				'latitude',
				'longitude',
				'region'
			];

			let match = true;
			for (let i = 0, l = props.length; i < l; i++) {
				if (location.hasOwnProperty(props[i]) === false) {
					match = false;
				}
			}

			return match;
		}
	}
}

