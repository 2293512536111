export default class PersonalizationTriggerController {

    constructor ($scope, $sce, partialService, functionExecutionControl) {
        'ngInject';

        this.$scope = $scope;
        this.$sce = $sce;

        this.partialService = partialService;
        this.functionExecutionControl = functionExecutionControl;

        this.zoneName = '';
        this.trustedHtml = '';

        this.triggerRegistered = true;
        this.userHasinteracted = false;
        this.modalHasTriggered = false;
        this.idleState = false;

        this.idleTimer = null;
        this.analyticIdBase = null;
        this.utmSource = null;
    }

    $onInit = () => {
        this.$scope.$$postDigest(() => {
            this.getUtmParams();
            this.checkModalTriggerConditions();

            this.getBodyContent();
            this.setAnalyticsIdBase();

            if (this.triggerRegistered) {
                this.userInactivityCheck();

                $(window).on('scroll', this.functionExecutionControl.debounce(() => {
                    if (!this.modalHasTriggered && !this.userHasinteracted) {
                        this.userInactivityCheck();
                    }
                }, 200));
            }
        });
    }

    getBodyContent = () => {
        if (this.zoneName !== '') {
            this.partialService.getZoneContent(this.zoneName,

                (responseContent) => {
                    this.trustedHtml = this.$sce.trustAsHtml(responseContent);
                },
                (errorMessage) => {
                    console.log(errorMessage)
                });
        }
    };

    userInactivityCheck = () => {
        if(!this.userHasinteracted) {
            $(document).on('mousedown keydown scroll', this.functionExecutionControl.debounce(() => {
                this.userHasinteracted = true;
                clearTimeout(this.idleTimer);
            }, 200));
            this.startIdleTimer();
        } else {
            clearTimeout(this.idleTimer);
        }
    };

    startIdleTimer = () => {
        this.idleTimer = setTimeout(() => {
            this.idleState = true;
            this.modalHasTriggered = true;
            this.$scope.$broadcast('fcaModal:open');

        }, this.idleDuration);
    };

    getUtmParams = () => {
        const utmParams = new URLSearchParams(window.location.search);

        if(utmParams.has('utm_source')) {
            this.utmSource = utmParams.get('utm_source');
        }
    };

    checkModalTriggerConditions = () =>  {
        this.triggerRegistered = this.utmSource != null && (this.utmSource.includes('Addressed-Mail') || this.utmSource.includes('CRM'));
    }

    setAnalyticsIdBase = () => {
        this.analyticIdBase = 'personalization-scenario-crm-';
    }
}
