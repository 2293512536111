import angular from "angular";
import translate from "angular-translate"

import autoComplete from 'angucomplete-alt'

import template from './autocomplete.html'

import './_autocomplete.scss'

export default angular
	.module('fca.autocomplete', [translate, "angucomplete-alt"])
	.controller('fcaAutocompleteController', AutocompleteController)
	.component('fcaAutocomplete', {
		template: template,
		controller: 'fcaAutocompleteController',
		controllerAs: '$ctrl',
		bindings: {
			apiUrl: '@',
			placeholder: '@',
			fields: '@',
			searchTxt: '@',
			inputId: '@',
			onSelected: '&'
		}
	})
	.name;

/**
 * @ngdoc controller
 * @name fca.autocomplete.controller:fcaAutocompleteController
 * @description [TODO]
 * @example
 * <pre>[TODO]</pre>
 */
function AutocompleteController() {
	'ngInject';

	/**
	 * @ngdoc property
	 * @name data
	 * @propertyOf fca.autocomplete.controller:fcaAutocompleteController
	 * @type {Object}
	 * @description Data returned by the autocomplete component
	 */
	this.data = null;

	this.optionSelected = false;
	this.displayError = false;

	/**
	 * @ngdoc method
	 * @name select
	 * @methodOf fca.autocomplete.controller:fcaAutocompleteController
	 * @description Called on user click on search button or the form submit expose data outside component
	 * @param  {Object} $evt DOM element event
	 * @example
	 * <pre></pre>
	 */
	this.select = ($evt) => {
		$evt.preventDefault();

		let data = this.data;

		this.displayError = this.data === null || this.data === undefined;

		if (!this.displayError) {
			if (data !== null && data.hasOwnProperty('originalObject')) {
				data = data.originalObject;
			}

			/* Pass data outside component */
			this.onSelected({
				data: data
			});
		}
	};

	this.selectedOption = (data) => {
		this.optionSelected = true;
		this.displayError = false;
		this.data = data;
	};
}
