import angular from 'angular';
import config from '../../config';
import 'slick-carousel'

import controller from './vehicle-selector.controller'

import './vehicle-selector.scss';

export default angular
    .module(`${config.appName}.vehicle`, [])
    .directive("crmVehicleSelector", crmVehicleSelector)
    .name


function crmVehicleSelector() {
    return {
        restrict: 'A',
        scope: true,
        controllerAs: '$ctrl',
        bindToController: {
            prevArrowLabel: "@",
            nextArrowLabel: "@",
            eventCode: "@"
        },
        controller: controller
    };
}

