import $q from 'q'
import $ from 'jquery';
import config from "../../config";

/* @ngInject */
class contestFormController {
    constructor ($scope, contestService, $timeout, $location, googleTagManager) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$location = $location;
        this.googleTagManager = googleTagManager;
        this.contestService = contestService;
        this.formSucceed = false;
        this.disableSubmit = false;
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.postalCode = "";
        this.phoneNumber = "";
        this.addressOne = "";
        this.addressTwo = "";
        this.city = "";
        this.province = "";
        this.purchaseIntent = "";
        this.provinceList = FCA_SITES_CONFIG.location;
        this.extraBallotCheckbox = false;
        this.checkboxAgreeConditions = false;
        this.postalCodeRegex = /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz][ -]?\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]\d$/;
        this.phoneRegex = '^\\(?([2-9][0-9]{2})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
        this.nameRegex = /^([A-Za-z_.-]{2,})$/;

        $scope.$watch(() => {
                return this.extraBallot;
            },
            (newVal, oldVal) => {
               if (this.extraBallot == false) this.extraBallotCheckbox = false;
            });
    }

    $onInit = () => {
        this.contestService.validateUserInfomations = this.validateUserInfomations;
        this.contestService.formIsInvalid = this.formIsInvalid;
        this.contestService.formSubmited = this.formSubmited;
        this.errorMessage = false;
        this.baseUrl = this.$location.host();
        this.initPca();
    }

    submitForm = () => {
        this.disableSubmit = true;
        angular.forEach(this.$scope.contestForm.$error.required, function(field) {
            field.$setDirty();
        });
        this.contestService.submit(this.folderName, this.eventCode);
    }

    formIsInvalid = () => {
        this.$timeout(() => {
            this.disableSubmit = false;
        })
    }

    formSubmited = () => {
        this.formSucceed = true;
        this.googleTagManager.push({ event: 'gtmCustomEvent', gtmCustomEvent: 'Contest-Form-Submission-Complete' });

        $('html, body').animate({scrollTop: $('#zones_body_blocks_contest-form').offset().top -100 });
    }

    validateUserInfomations = () => {
        this.defer = $q.defer();

        if(this.$scope.contestForm) {
            this.$scope.contestForm.submitted = true;
        }
        this.errorMsgVisible = false;

        if( this.$scope.contestForm && this.$scope.contestForm['$invalid'] || (this.extraBallot && this.contestService.extraBallotRequired())) {
            this.errorMsgVisible = true;
            this.$timeout(() => {
                $('html, body').animate({scrollTop: $('#contest-form-errors').offset().top -100 });
            });
            this.defer.reject('Error validating the user');
        } else {

            this.errorMsgVisible = false;
            this.contestService.userInformation = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                postalCode: this.postalCode,
                phoneNumber: this.phoneNumber,
                address: this.addressOne + ' ' + this.addressTwo,
                city: this.city,
                province: this.province,
                purchaseIntent: this.purchaseIntent,
                checkboxAgreeConditions: this.checkboxAgreeConditions,
                optIn: this.extraBallotCheckbox,
                dealerCode: this.contestService.userInformation.dealerCode,
                brandGroup: this.contestService.userInformation.brandGroup,
                multipleVois: this.extraBallotCheckbox ? this.contestService.userInformation.multipleVois : []
            }
            this.defer.resolve(this.contestService.userInformation);
        }

        return this.defer.promise;
    }

    formatPhoneNumber = (event) => {
        if(this.isModifierKey(event)) {
            return;
        }

        let target = event.target;
        const input = event.target.value.replace(/\D/g, '').substring(0,10);
        const zip = input.substring(0,3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if(input.length > 6) {
            target.value = `(${zip}) ${middle}-${last}`;
        } else if(input.length > 3) {
            target.value = `(${zip}) ${middle}`;
        } else if(input.length > 0) {
            target.value = `(${zip}`;
        }
        this.phoneNumber = target.value;
    };

    enforceFormat = (event) => {
        if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    };

    isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow Number line
            (key >= 96 && key <= 105) // Allow NumPad
        );
    };

    isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    showMessage = (input) => {
        return input.$invalid && (input.$dirty || input.$touched);
    }

    change = (key, value) => {
        this.googleTagManager.push({ event: 'gtmCustomEvent', gtmCustomEvent: key + value });
    }

    initPca = (options = {}) => {
        const pcaFields = [
            {element: 'addressOne', field: 'Line1'},
            {
                element: 'addressTwo',
                field: 'Line2',
                mode: pca.fieldMode.POPULATE
            },
            {
                element: 'city',
                field: 'City',
                mode: pca.fieldMode.POPULATE
            },
            {
                element: 'province',
                field: 'ProvinceName',
                mode: pca.fieldMode.POPULATE
            },
            {
                element: 'postalCode',
                field: 'PostalCode',
                mode: pca.fieldMode.POPULATE
            }
        ];

        const pcaOptions = angular.extend({},
            config.pca.options, options);

        this.pcaControl = new pca.Address(pcaFields, pcaOptions);
        this.pcaControl.listen('populate', this.onPcaPopulate);

        this.pcaControl.listen('ready', function () {
            this.pcaControl.enable();
        });
    }

    onPcaPopulate = (address) => {
        this.addressOne = address.Line1;
        this.addressTwo = address.Line2;
        this.city = address.City;
        this.province = address.ProvinceCode;
        this.postalCode = address.PostalCode;
    };
}

export default contestFormController;

