import angular from 'angular';

export default angular
	.module('fca.layout.regionalOffersContent', [])
	.directive('fcaProvincialContent', fcaProvincialContent)
	.name;

function fcaProvincialContent() {
	const regionProvinceMap = {
		ON: {region: 'ontario', code: 'on', default: false},
		QC: {region: 'quebec', code: 'qc', default: false},
		BC: {region: 'pacific', code: 'bc', default: false},
		AB: {region: 'alberta', code: 'ab', default: false},
		MB: {region: 'prairies', code: 'mb', default: false},
		NB: {region: 'atlantic', code: 'nb', default: false},
		NL: {region: 'atlantic', code: 'nl', default: false},
		NT: {region: 'pacific', code: 'nt', default: false},
		NS: {region: 'atlantic', code: 'ns', default: false},
		NU: {region: 'alberta', code: 'nu', default: false},
		PE: {region: 'atlantic', code: 'pe', default: false},
		SK: {region: 'prairies', code: 'sk', default: false},
		YT: {region: 'pacific', code: 'yt', default: false},
		DE: {region: 'default', code: 'de', default: true}
	};

	return {
		restrict: 'A',
		scope: true,
		bindToController: {
			currentProvinceCode: '@'
		},
		controllerAs: 'fcaProvincialContentOverlay',
		controller: FcaProvicialContentController
	};

	function FcaProvicialContentController($rootScope, userLocation) {
		'ngInject';
		this.isVisible = false;

		this.$onInit = () => {
			this.displayProvincialContent(userLocation.getLocationProvince(),
				userLocation.getLocationRegion());

			$rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => { // eslint-disable-line max-len
				this.displayProvincialContent(province, region);
			});
		};

		this.displayProvincialContent = (province, region) => {
			this.setLocation(province, region);
			this.updateContentVisibility();
		};

		this.setLocation = (province, region) => {
			this.setProvince(province);
			this.setRegion(region);
		};

		this.setProvince = (province) => {
			this.province = province;
		};

		this.setRegion = (newRegion) => {
			this.region = newRegion;
		};

		this.updateContentVisibility = () => {
			this.isVisible = this.currentProvinceCode.toLowerCase() == regionProvinceMap[this.province].code;
		};
	}
}

