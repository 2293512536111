import angular from 'angular';

import $ from 'jquery';
import Dialog from './../../classes/focus-trap'

import './_modal-window.scss';

export default angular.module('fca.modal-window', [])
    .directive('fcaModalWindow', fcaModalWindow)
    .name


function fcaModalWindow() {
    return {
        restrict: 'A',
        scope: true,
        bindToController: {
            modalType: '@',
            addClass: '@',
            componentName: '@',
            disableOutsideClick: '<?'
        },
        controllerAs: '$fcaModalCtrl',
        controller: FcaModalWindowController
    };

    /*
    * Make sure you wrap the modal content in <fca-modal-content></fca-modal-content>.
    * Trigger window with $fcaModalCtrl.openModal.
    * Close modal with $fcaModalCtrl.closeModal.
    * Add 'data-fca-modal-close' to elements with $fcaModalCtrl.closeModal to help with the focus trap function.
    */

    function FcaModalWindowController($document, $element, $timeout, $scope, $rootScope) {
        'ngInject';

        let $ctrl = this;
        let lastScroll;
        let dialogRef;

        const mySavedVehiclesOpenModalBtn = $element.children('.C_UA-on-off');
        const KEY_ESC = 27;
        const onKeyDown = (event) => {

            // ESCAPE key pressed
            if( event.keyCode == KEY_ESC ) {
                let openDialogs = $('[data-fca-modal-content-wrapper]');
                let openDialogsLength = openDialogs.length;

                if(openDialogsLength <= 1) {
                    // Keep event listener if multiple modals open.
                    $ctrl.closeModal();
                    document.removeEventListener('keydown', onKeyDown);
                } else {
                    // If more than one instance of fcaModal Window, only close the most recently opened.
                    // (The lowest in the DOM.)
                    // Trigger closeModal if the modal window matches this controller id.
                    let lastOpenDialog = openDialogs[openDialogsLength - 1];
                    let lastOpenDialogId = $(lastOpenDialog).attr('data-modal-id');

                    if(parseInt(lastOpenDialogId) === parseInt($scope.modalWindow.modalId) ) {
                        $ctrl.closeModal();
                    }
                }
            }
        };

        $ctrl.modalIsOpen = false;
        $ctrl.scrollPaused = false;

        $scope.modalWindow = {};
        $scope.modalWindow.modalId = $scope.$id;

        $ctrl.$onInit = () => {
            window.addEventListener('scroll', event => {
                if (!$ctrl.scrollPaused) {
                    lastScroll = window.scrollY || window.pageYOffset;
                }
            });

            $scope.$on('fcaModal:open', () => {
                $ctrl.openModal();
            });

            $scope.$on('fcaModal:close', () => {
                $ctrl.closeModal();
            });
        };

        $ctrl.openModal = ($event) => {
            if(!$ctrl.modalIsOpen) {
                let target = null;

                if ($event != null) {
                    $event.stopPropagation();
                    $event.preventDefault();

                    target = $event.currentTarget;
                }

                $ctrl.modalIsOpen = true;
                $ctrl.pauseScrolling();
                document.addEventListener('keydown', onKeyDown);

                $timeout(() => {
                    $('body').append($('.C_MW-container'));
                    $ctrl.focusTrapActive(target);
                });

            }
        };

        $ctrl.closeModal = () => {
            $timeout(() => {
                let openDialogs = $('[data-fca-modal-content-wrapper]');
                let openDialogsLength = openDialogs.length;

                // Keep scrolling locked if multiple modal instances are open
                if(openDialogsLength <= 1) {
                    $ctrl.resumeScrolling();
                    $rootScope.$broadcast('fca-modal-is-closed');
                }

                if ($('.modalActOpenBtn').length) {
                    mySavedVehiclesOpenModalBtn.removeClass(
                        'modalActOpenBtn');
                }

                dialogRef = null;
                $ctrl.modalIsOpen = false;
            });
        };

        $ctrl.outsideClick = () => {
            if (!$ctrl.disableOutsideClick) {
                $ctrl.closeModal();
            }
        };

        $ctrl.pauseScrolling = () => {
            $ctrl.scrollPaused = true;
            $('body').addClass('overflow-hidden');
            $('html').addClass('overflow-hidden');
        };

        $ctrl.resumeScrolling = () => {
            $ctrl.scrollPaused = false;
            $('body').removeClass('overflow-hidden');
            $('html').removeClass('overflow-hidden');
        };

        $ctrl.focusTrapActive = (target) => {
            $timeout(() => {
                if (!dialogRef) {
                    // Set focus trap on newly appended modal dom element
                    let dialogElements = document.querySelectorAll('[data-fca-modal-content]');
                    let dialogElementsLength = dialogElements.length;

                    dialogRef = new Dialog(dialogElements[dialogElementsLength - 1]);
                    dialogRef.addEventListeners(null, null);
                    //refresh the focus trap by calling Dialog.open again
                    dialogRef.open();

                    if(target != null) {
                        // This makes sure the initial trigger is focused upon when closing modal
                        dialogRef.resetInitialFocusedEl(target);
                    }
                }
            })
        };

        $ctrl.closeModalIfHidden = () => {
            if ($('.C_MW-container').is(':hidden')) {
                $ctrl.closeModal();
            }
        };

    }
}

