import angular from 'angular';
import config from '../config';

import geoLocatorSatus from './geolocator/geolocator-status/geolocator.status.component';
import geolocator from './geolocator/geolocator.component'
import postalCodeInput from './postal-code-input/postalCodeInput.component'
import autoComplete from './autocomplete/autocomplete.component'
import selectDealerDropdown from './select-dealer-dropdown/select-dealer-dropdown.component'
import captcha from './captcha/captcha.component'
import fcaLegalTooltip from './fca-legal-tooltip/fca-legal-tooltip.component'
import fcaVr360 from './vr360/fca-vr360.component'
import fcaChatPopup from './chat-popup/fca-chat-popup.component'

export default angular
    .module(`${config.appName}.components`, [geoLocatorSatus, geolocator, postalCodeInput, autoComplete, selectDealerDropdown, captcha, fcaLegalTooltip, fcaVr360, fcaChatPopup])
    .name;
