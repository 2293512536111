import angular from "angular";
import ng from 'angular';

import config from '/src/app/config'

import template from "./geolocator.status.html";
import storageService from "./../../../services/storage.service"
import fcaGeolocator from './../geolocator.service'

import geolocatorOverlayTheme from './../geolocator.overlay.theme.html'

import './geolocator.status.scss';


export default angular
	.module('fca.geolocator', [fcaGeolocator])
	.controller('fcaGeolocatorStatusController', fcaGeolocatorStatusController)
	.component("fcaGeolocatorStatus",{
		controller: 'fcaGeolocatorStatusController',
		controllerAs: '$ctrl',
		template: template,
		transclude: true,
		bindings: {
			skin: '@',
			location: '<?',
			customLabel: '@?',
			viewAllUrl: '@?',
			brand: '@',
			displayType: '@',
			showProvince: '<',
			useTransclude: '<',
			analyticsId: '@',
			regionRedirect: '<',
			hideCity: '@'
		}
	})
	.name;

function fcaGeolocatorStatusController($scope, $rootScope, $element, userLocation, fcaGeolocator, cookieLocation) {
	'ngInject'


	/* eslint-disable no-invalid-this */
	/**
	 * @ngdoc property
	 * @name location
	 * @propertyOf fca.geolocator.controller:fcaGeolocatorStatusController
	 * @description [TODO]
	 * @type {Object}
	 */
	this.location = null;

	this.geolocatorOverlayTheme = geolocatorOverlayTheme;


	// Only display a city or postal code if the user manually chose to change it's location in the dealer-locator
	this.locationUpdated = false;

	this.useTransclude = false;

	// this.isCookiesEnabled = storageService.isCookiesEnabled();

	/**
	 * @ngdoc property
	 * @name dialogModel
	 * @propertyOf fca.geolocator.controller:fcaGeolocatorStatusController
	 * @description Dialog model data to inject into modal
	 * @type {Object}
	 */
	this.dialogModel = {
		viewAllUrl: null,
		title: 'geolocation.set.location.title'
	};

	this.$onInit = () => {
		this.location = cookieLocation.getLocation();
		this.setLocationDefaultLabel(userLocation.getLocationLabel());

		let title = this.dialogModel.title;

		console.debug("initialized geolocator status controller");
	};

	/**
	 * @ngdoc method
	 * @name setLocation
	 * @propertyOf fca.geolocator.controller:fcaGeolocatorStatusController
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	this.setLocation = (data) => {
		let location = data || {};
		if (!ng.equals(location, {})) {
			this.location = ng.extend({}, this.location, location);
			let eventName = fcaGeolocator.getLocationChangedEvent();

			$scope.$emit(eventName, [this.location]);

			// Broadcast a JavaScript event for the vehicle recommender (potential refactor longterm)
			$('.js-vehiclerecommender').trigger({
				type: 'vehicle-recommender-event:location-change',
				lat: this.location.latitude,
				long: this.location.longitude
			});

			if (this.regionRedirect) {
				let currentUrl = $location.path();
				let currentUrlRegion = urlRegionMapping.getLocalizedRegionNameByUrl(currentUrl, window.FCA_SITES_CONFIG.language) || '';
				let newUrlByRegion = urlRegionMapping.getUrlByRegion(this.location.region);
				let newUrl = newUrlByRegion;
				if (!currentUrl.endsWith(currentUrlRegion)) {
					newUrl = newUrl.concat(currentUrl.substring(
						currentUrl.indexOf(currentUrlRegion) + currentUrlRegion.length,
						currentUrl.length));
				}
				$rootScope.$emit('geolocatorStatus.setLocation', this.location.region, newUrl);
			}

		}
	};

	this.setLocationDefaultLabel = locationLabel => {
		this.defaultLocationLabel = ` (${locationLabel})`;
	};

	this.sendAnalyticsId = () => {
		let id = "";
		if (this.analyticsId) {
			id = this.analyticsId;
		}
		return id;
	};

	$rootScope.$on('regionalOffers.locationChange', (ev, pos, prov, reg, locationLabel) => {
		this.setLocationDefaultLabel(locationLabel);
	});

	/* Listen if the location is changed outside this component */
	let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
	$scope.$on(eventUpdateName, (evt, args) => {
		let [location] = args;

		if (!ng.equals(location, this.location)) {
			this.location = location;
		}

		this.setLocationDefaultLabel(config.location[location.province].desc);
	});
}

