import $ from 'jquery';
import tippy from 'tippy.js';

/* @ngInject */
class Legalnotesontroller {
    constructor ($element, $rootScope, $scope) {
        this.$element = $element;
        this.$rootScope = $rootScope;
        this.$scope = $scope;

        this.closeBtnLabel;
        this.defaultOpenBtnLabel;
    }

    $onInit = () => {
        if(window.FCA_SITES_CONFIG.language == 'fr') {
            this.closeBtnLabel = 'Fermer l\'avis';
            this.defaultOpenBtnLabel = 'Ouvrir l\'avis';

        } else {
            this.closeBtnLabel = 'Close disclaimer';
            this.defaultOpenBtnLabel = 'Open disclaimer';
        }

        this.defaultLabel = this.defaultOpenBtnLabel;
    }

   $postLink = () => {

        angular.element(document).ready(() => {
            let contentNode = this.$element[0].querySelectorAll('.legal-tooltip-content')[0];
            let tooltipToggle = this.$element[0].querySelectorAll('[data-legal-tooltip-toggle]')[0];
            let tooltipCloseBtn = this.$element[0].querySelectorAll('[data-legal-tooltip-close]')[0];

            /* Harvest legal mention content from DOM */
            if (this.value) {
                angular.element('ng-transclude', this.$element[0]).html(this.value);
            }

            tooltipCloseBtn.setAttribute('aria-label', this.closeBtnLabel);

            /*
             * Get the parent module box for each disclaimer trigger
             * Cannot append to body for all disclaimer tooltips
             * this is due to complex z-index scenarios caused by scrolling animation
             *
             * Setting .vr360-int-wrapper as parent div shows disclaimers
             * in fullscreen vr-360 in Firefox and IE
             */
            let $parentDiv;

            if($(this.$element[0]).parents('.vr360-inst-wrapper').length > 0) {
                $parentDiv = $(this.$element[0]).parents('.vr360-inst-wrapper');
            } else if ($(this.$element[0]).parents('.js-legal-tooltip-container').length > 0) {
                $parentDiv = $(this.$element[0]).parents('.js-legal-tooltip-container');
            } else if ($(this.$element[0]).parents('.layout-module').length > 0) {
                $parentDiv = $(this.$element[0]).parents('.layout-module');
            } else {
                $parentDiv = $('body');
            }

            let isNormalPlacement = true;

            // Condition for disclaimers in panos. they need to be placed to the side to avoid popping under the the header (cannot be fixed with z-index)
            if ($parentDiv[0].dataset &&
                $parentDiv[0].dataset.groupId &&
                $parentDiv[0].dataset.groupId === 'stacked-anim-group-0') {
                isNormalPlacement = false;
                $parentDiv = $parentDiv.find('[data-tooltip-container]');
            }

            const normalPlacement = this.forcePlacement ? this.forcePlacement : 'top';
            const options = angular.extend({
                allowHTML: true,
                placement: isNormalPlacement ? normalPlacement : 'right',
                trigger: 'click',
                interactive: true,
                content: contentNode,
                theme: 'fcatooltips',
                arrow: true,
                appendTo: $parentDiv[0],
                zIndex: 10000000,
                onShown(instance) {
                    const listenerOptions = {
                        once: true
                    }
                    instance.popper.querySelector('[data-legal-tooltip-content]').focus();
                    instance.popper.querySelector('[data-legal-tooltip-close]').addEventListener('click', () => {
                        instance.hide();
                        tooltipToggle.focus();
                    }, listenerOptions);
                    instance.popper.querySelector('.legal-tooltip-content').addEventListener('keydown', this.setFocusTrap);
                },
                onHide(instance) {
                    instance.popper.querySelector('.legal-tooltip-content').removeEventListener('keydown', this.setFocusTrap);
                },
                onHidden() {
                }
                // more options: https://atomiks.github.io/tippyjs/#all-settings
            }, this.options);

            const tippyInst = tippy(this.$element[0], options); // eslint-disable-line new-cap
            // Store reference to the tippy popup on the trigger (needed by pano carousel)
            this.$element[0].dataset.tippyPopper = tippyInst.popper.id;

            this.setFocusTrap = () => {
                let element = event.currentTarget;
                this.focusTrap(event, element, tooltipToggle);
            }
        });
    };

    focusTrap = (e, element, tooltipToggle) => {
        const focusableElements = $(element).find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];
        const mainContainer = $(element).find('[data-legal-tooltip-content]');

        if (e.key === 'Tab' || e.code === 'Tab') {
            if ( e.shiftKey ) {
                if (document.activeElement === firstFocusableElement) {
                    mainContainer.focus();
                    e.preventDefault();
                } else if (document.activeElement.hasAttribute('data-legal-tooltip-content')) {
                    lastFocusableElement.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    mainContainer.focus();
                    e.preventDefault();
                }
            }
        } else if(e.key === 'Escape' || e.code === 'Escape') {
            tippy.hideAll();
            tooltipToggle.focus();
        } else {
            return;
        }
    }
}

export default Legalnotesontroller;
