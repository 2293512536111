class GoogleTagManager {
    static $inject = ['$window']
    constructor($window) {
        this.$window = $window
    }

    push = (data) => {
        try {
            this.$window.dataLayer.push(data);
        } catch (e) {
        }
    }
}

export default angular.module('googleTagManager', [])
    .service('googleTagManager', GoogleTagManager)
    .name
