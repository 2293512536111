import angular from 'angular'
import config from '../../config';

export default angular.module(`${config.appName}.cookie-location`, ['ngCookies', 'base64'])
	.service('cookieLocation', CookieLocation)
	.name

function CookieLocation(
	geolocatorCookie,
	geolocatorCookieExpire) {
	'ngInject';

	const cookieName = config.locationCookieName;
	const EXPIRES = geolocatorCookieExpire.getDefault();
	const COOKIE_CONNECTOR = new geolocatorCookie(cookieName, EXPIRES);

	// Return current stored location or null
	this.getLocation = () => {
		return COOKIE_CONNECTOR.get();
	};

	// Set the current location in cookie
	this.setLocation = (locParam) => {
		return COOKIE_CONNECTOR.set(locParam);
	};

	// Check if cookie exists
	this.isCookieExists = () => {
		return COOKIE_CONNECTOR.isCookieExists();
	};
}
