import angular from 'angular';
import config from '../../config';

import ngDialog from 'ng-dialog'

import template from '../../components/geolocator/geolocator.overlay.theme.html'
import controller from './../../components/geolocator/geolocator.dialog.controller'

import './dialog.scss';


export default angular
	.module(`${config.appName}.dialog`, [ngDialog])
	.directive("confirm", crmVehicleSelector)
	.config(['ngDialogProvider', function (ngDialogProvider) {
		ngDialogProvider.setDefaults({
			className: 'fca-dialog-overlay',
			plain: true,
			showClose: true,
			closeByDocument: true,
			closeByEscape: true
		});
	}])
	.name

function crmVehicleSelector(ngDialog) {
	'ngInject';

	return {
		restrict: 'A',
		scope: true,
		controllerAs: '$ctrl',
		controller: CrmVehicleSelector
	};

	function CrmVehicleSelector($scope, $element, $timeout) {
		'ngInject';

		$element.on('click', (evt) => {
			evt.preventDefault();
			ngDialog.open({
				scope: $scope,
				template: template,
				controller: ("controller", controller)
			});
		});
	}
}
