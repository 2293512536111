import angular from "angular";
import ng from "angular";

import PhotoSphereViewer from 'photo-sphere-viewer/dist/photo-sphere-viewer.min.js';

import 'photo-sphere-viewer/dist/photo-sphere-viewer.min.css'
import './_vr360.scss'
import './_vr360-navigation.scss'

import template from './fca-vr360.html'

export default angular
	.module('fca.fca-vr360', [])
	.controller('fcaVr360Controller', FcaVr360Controller)
	.component('fcaVr360', {
		template: template,
		controller: 'fcaVr360Controller',
		controllerAs: 'vr360',
		bindings: {
			object: '<',
			brand: '@',
			brandLogoPath: '@',
			containerId: '@',
			iconReset: '@',
			iconPause: '@',
			contextId: '@',
			groupId: '@',
			nameplate: '@',
			caption: '@',
			customText: '<',
			selectedIndex: '<',
			activateCta: '@',
			analyticsLabel: '@',
			fullscreenMode: '<',
			galleryItemId: '@',
			analyticsId: '@',
			analyticsCategory:'@'
		}
	})
	.name;

function FcaVr360Controller($scope, $compile, $element, $timeout, $document, $window, $location, googleTagManager) {
	'ngInject';

	let vm = this;
	vm.isGallery = false;
	vm.selected360 = 0;
	vm.modalId = 0;
	vm.isPSVActive = false;
	vm.$container;
	vm.trims = [];
	vm.autorotate = false;
	vm.disableTrimButtons = false;

	let PSV = '';
	let obj360 = '';
	let customText = '';
	let lfaTimer;
	let analyticsCategory = '';

	vm.$onInit = () => {
		vm.$container = $element.find('.vr360-module360-container');
		customText = vm.customText;
		vm.selected360 = vm.selectedIndex;
		vm.defaultObject = vm.object[vm.selected360];
		analyticsCategory = vm.analyticsCategory ? vm.analyticsCategory : `${vm.contextId} ${vm.groupId}`;
		vm.populateTrims();

		$scope.$on('galleryPage.triggerActiveSlideContent', vm.triggerActiveSlideContent);
		$scope.$on('galleryPage.collapse', vm.destroy360);
		$scope.$on('initInterior360', vm.init360);

	};

	vm.calculHeight = () => {

		let windowWidth = $window.innerWidth;
		let smallBreakpoint = 467;
		let height = $('.wrapper360-demo')[0].clientHeight;

		if ($element.hasClass('has-multiples-trims')) {
			if (windowWidth < smallBreakpoint) {
				$element.css('margin-top', height);
				$('.wrapper360-demo').css('top', height*-1);
			} else {
				$element.css('margin-top', '');
				$('.wrapper360-demo').css('top', '');
			}
		}
	};

	vm.$onChanges = function(changes) {
		if (changes.fullscreenMode && changes.fullscreenMode.currentValue != changes.fullscreenMode.previousValue) {
			if (vm.$container && vm.isPSVActive) {
				if (vm.fullscreenMode) {
					vm.enterFullscreen();
				} else {
					vm.exitFullscreen();
				}
			}
		}
	};

	vm.init360 = () => {
		obj360 = vm.object[vm.selected360];

		// override the default players control icons with custom ones
		PhotoSphereViewer.ICONS['zoom-in.svg'] = '<button class="psv-button-a psv-button-a-zIn"><?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1_zIn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 48.1" style="enable-background:new 0 0 60 48.1;" xml:space="preserve"><style type="text/css">.st0{fill:#FFFFFF;}</style><path class="st0" d="M42.1,21.9h-9.6c-0.2,0-0.3-0.1-0.3-0.3v0V12c0-0.4-0.3-0.7-0.7-0.7h-2.9c-0.4,0-0.7,0.3-0.7,0.7v9.6 c0,0.2-0.1,0.3-0.3,0.3h-9.6c-0.4,0-0.7,0.3-0.7,0.7v2.9c0,0.4,0.3,0.7,0.7,0.7h9.6c0.2,0,0.3,0.1,0.3,0.3v9.6 c0,0.4,0.3,0.7,0.6,0.7c0,0,0,0,0,0h2.9c0.4,0,0.7-0.3,0.7-0.7l0,0v-9.6c0-0.2,0.1-0.3,0.3-0.3l0,0h9.6c0.4,0,0.7-0.3,0.7-0.7v-2.9 C42.7,22.3,42.5,22,42.1,21.9C42.1,21.9,42.1,21.9,42.1,21.9z"/></svg></button>';
		PhotoSphereViewer.ICONS['zoom-out.svg'] = '<button class="psv-button-a psv-button-a-zOut"><?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1_zOut1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 48.1" style="enable-background:new 0 0 60 48.1;" xml:space="preserve"><style type="text/css">.st0{fill:#FFFFFF;}</style><path class="st0" d="M17.9,21.9h24.2c0.4,0,0.7,0.3,0.7,0.7v2.9c0,0.4-0.3,0.7-0.7,0.7H17.9c-0.4,0-0.7-0.3-0.7-0.7v-2.9 C17.2,22.2,17.5,21.9,17.9,21.9z"/></svg></button>';
		PhotoSphereViewer.ICONS['play.svg'] = '<button class="psv-button-a"><?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1_play" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 48.1" style="enable-background:new 0 0 60 48.1;" xml:space="preserve"><style type="text/css">.st0{fill:#FFFFFF;}</style><path class="st0" d="M40,22.3L22,11.9c-1-0.6-2.2-0.2-2.7,0.7c-0.2,0.3-0.3,0.6-0.3,1v20.8c0,1.1,0.9,2,2,2c0.3,0,0.7-0.1,1-0.3 l18-10.4c0.9-0.6,1.2-1.8,0.7-2.7C40.5,22.7,40.3,22.5,40,22.3z"/></svg></button>';
		PhotoSphereViewer.ICONS['fullscreen-in.svg'] = '<button class="psv-button-a"><?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1_fsIn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 48.1" style="enable-background:new 0 0 60 48.1;" xml:space="preserve"><style type="text/css">.st0{fill:#FFFFFF;}</style><g><path class="st0" d="M29.7,27.1l-5.5,5.5l2.4,2.4c0.2,0.2,0.3,0.4,0.3,0.8c0,0.6-0.5,1-1,1h-7.5c-0.6,0-1-0.5-1-1v-7.5 c0-0.6,0.5-1,1-1c0.3,0,0.5,0.1,0.8,0.3l2.4,2.4l5.4-5.5c0.1-0.1,0.3-0.2,0.3-0.2c0.1,0,0.3,0.1,0.3,0.2l1.9,1.9 c0.1,0.1,0.2,0.3,0.2,0.3C29.8,26.9,29.8,27,29.7,27.1z M42.8,19.8c0,0.6-0.5,1-1,1c-0.3,0-0.5-0.1-0.8-0.3l-2.4-2.4l-5.5,5.5 c-0.1,0.1-0.3,0.2-0.3,0.2s-0.3-0.1-0.3-0.2l-2-1.9c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.3l5.5-5.5l-2.4-2.4 c-0.2-0.2-0.3-0.4-0.3-0.8c0-0.6,0.5-1,1-1h7.5c0.6,0,1,0.5,1,1V19.8z"/></g></svg></button>';
		PhotoSphereViewer.ICONS['fullscreen-out.svg'] = '<button class="psv-button-a"><?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1_fsOut" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 48.1" style="enable-background:new 0 0 60 48.1;" xml:space="preserve"><style type="text/css">.st0{fill:#FFFFFF;}</style><g><path class="st0" d="M42.5,14.1l-5.4,5.4l2.4,2.4c0.2,0.2,0.3,0.4,0.3,0.8c0,0.6-0.5,1-1,1h-7.3c-0.6,0-1-0.5-1-1v-7.3 c0-0.6,0.5-1,1-1c0.3,0,0.5,0.1,0.8,0.3l2.4,2.4l5.4-5.4c0.1-0.1,0.3-0.2,0.3-0.2c0.1,0,0.3,0.1,0.3,0.2l1.9,1.9 c0.1,0.1,0.2,0.3,0.2,0.3C42.8,14,42.6,14.1,42.5,14.1z"/><path class="st0" d="M29.7,32.8c0,0.6-0.5,1-1,1c-0.3,0-0.5-0.1-0.8-0.3l-2.4-2.4l-5.4,5.4c-0.1,0.1-0.3,0.2-0.3,0.2 c-0.2,0-0.3-0.1-0.3-0.2l-1.9-1.9c-0.1-0.1-0.2-0.3-0.2-0.4s0.1-0.3,0.2-0.3l5.4-5.4l-2.4-2.4c-0.2-0.2-0.3-0.4-0.3-0.8 c0-0.6,0.5-1,1-1h7.3c0.6,0,1,0.5,1,1L29.7,32.8L29.7,32.8z"/></g></svg></button>';

		// initialize 360
		PSV = new PhotoSphereViewer({
			container: vm.$container.get(0),
			loading_img: vm.brandLogoPath,
			panorama: obj360['image'],
			autorotate: false,
			anim_speed : '1rpm',
			gyroscope: false,
			cors_anonymous: true,
			checkImageOrigin: false,
			move_speed: 1,
			caption: vm.caption,
			min_fov: obj360['maxZoom'],
			default_fov: obj360['defaultZoom'],
			navbar: [
				{
					title: 'disclaimer',
					className: 'navbar-caption-disclaimer',
					content: obj360['disclaimer']
				},
				'zoom', {
					title: 'Reset',
					className: 'custom-button-reset-rotation tooltip360',
					content: '<button class="psv-button-a"><img src="'+vm.iconReset+'"><span class="tooltiptext">'+customText.reset+'</span></button>',
					onClick: (function() {
						return function() {
							PSV.animate({
								x: obj360['defaultX'],
								y: obj360['defaultY']
							}, 2000);
							PSV.zoom(obj360['resetZoom']);
						};
					}())
				}, 'autorotate', {
					title: '',
					className: 'custom-button-pause-rotation tooltip360',
					content: '<button class="psv-button-a"><img src="'+vm.iconPause+'"><span class="tooltiptext">'+customText.pause+'</span></button>',
					onClick: (function() {
						return function() {
							PSV.stopAutorotate();
						};
					}())
				},
				'fullscreen'
			]
		});

		// enter/exit fullscreen triggered
		PSV.on('fullscreen-updated', function(enabled) {
			// we need to re-attach the tooltip to the fullscreen icon
			if (enabled) {
				obj360.fullScreen = true;
				setCustomText(customText.exitscreen, '.psv-fullscreen-button');
			} else {
				obj360.fullScreen = false;
				setCustomText(customText.fullscreen, '.psv-fullscreen-button');
			}

			let currentMarker = PSV.getCurrentMarker();

			if (currentMarker) {
				vm.$container.find('.module360Modals').css('top', (vm.$container.find('.psv-hud').height() - vm.$container.find('.module360Modals').height()) / 2 + 'px');
			}

			// recalculate size (fixes bug in safari)
			$window.dispatchEvent(new Event('resize'));
		});

		PSV.on('position-updated', function(enabled) {
			if(vm.autorotate) {
				vm.showLFA();
			}
		});

		// hide/show the pause/play button interchangely
		PSV.on('autorotate', function(enabled) {
			if (enabled) {
				vm.autorotate = true;
				PSV.zoom(10);
				vm.$container.find('.psv-autorotate-button').hide();
				vm.$container.find('.custom-button-pause-rotation').show();
			} else {
				vm.autorotate = false;
				vm.$container.find('.psv-autorotate-button').show();
				vm.$container.find('.custom-button-pause-rotation').hide();
			}
		});

		vm.populateHotspots(obj360['hotspots']);

		PSV.on('select-marker', function(marker) {
			showModal(marker.id, '');
		});

		// 360 loaded and ready for any DOM injections/manipulations
		PSV.on('ready', function() {
			vm.ready360();
		});

		vm.isPSVActive = true;

		if (vm.fullscreenMode) {
			vm.enterFullscreen();
		}
	};

	vm.ready360 = () => {

		let vr360Buttons = vm.$container.find('.psv-autorotate-button, .psv-fullscreen-button, .psv-zoom-button-minus, .psv-zoom-button-plus, .psv-zoom-button-handle, .custom-button-reset-rotation, .custom-button-pause-rotation');

		let arrow_keys_handler = function(e) {
			switch(e.keyCode) {
				case 37: case 39: case 38: case 40: e.preventDefault(); break;
				default: break;
			}
		}
		vm.$container.find('.custom-button-pause-rotation').hide();
		vm.$container.find('.modalBackground').hide();

		// inject tooltip to custom buttons
		setCustomText(customText.play, '.psv-autorotate-button');
		setCustomText(customText.fullscreen, '.psv-fullscreen-button');
		setCustomText(customText.zoomout, '.psv-zoom-button-minus');
		setCustomText(customText.zoomin, '.psv-zoom-button-plus');
		setCustomText(customText.zoom, '.psv-zoom-button-handle');
		setCustomText(customText.reset, '.custom-button-reset-rotation');

		vm.$container.find('.psv-navbar').appendTo('.psv-container');
		vm.$container.find('.psv-canvas').attr('title','360 photo');
		vm.$container.find('.psv-canvas').attr('tabindex','0');
		vm.$container.find('.psv-canvas-container').attr('role','img');
		vm.$container.find('.psv-canvas-container').attr('aria-label',customText.v360);
		vm.$container.find('.psv-canvas').focus(function(e) {
			PSV.startKeyboardControl();
			window.addEventListener('keydown',arrow_keys_handler, false);
		});
		vm.$container.find('.psv-canvas').blur(function() {
			PSV.stopKeyboardControl();
			window.removeEventListener('keydown',arrow_keys_handler, false);
		});
		vm.$container.find('.psv-canvas').focus();
		vm.$container.find('.psv-button-a-zIn').keyup(function(event) {
			if (event.keyCode == 13) {
				PSV.zoomIn();
			}
		});
		vm.$container.find('.psv-button-a-zOut').keyup(function(event) {
			if (event.keyCode == 13) {
				PSV.zoomOut();
			}
		});
		vm.$container.find('.psv-marker').attr('role','img');
		vm.$container.find('.psv-marker').attr('aria-label',customText.vMarker);
		vm.$container.find('.psv-marker').attr('tabindex','0');
		vm.$container.find('.psv-marker').keyup(function(event) {
			if (event.keyCode == 13) {
				let theMarkerID = $(this).attr('id').split('-');
				showModal(theMarkerID[2], '');
			}
		});

		// Set data analytics values on UI buttons
		vm.$container.find('.psv-autorotate-button').attr('title',customText.play);
		vm.$container.find('.psv-zoom-button-minus').attr('title',customText.zoomout);
		vm.$container.find('.psv-zoom-button-plus').attr('title',customText.zoomin);
		vm.$container.find('.psv-zoom-button-handle').attr('title',customText.zoom);
		vm.$container.find('.custom-button-reset-rotation').attr('title',customText.reset);
		vm.$container.find('.custom-button-pause-rotation').attr('title',customText.pause);
		vm.$container.find('.psv-fullscreen-button').attr('title',customText.fullscreen);
		vm.$container.find('.psv-autorotate-button').attr({'data-analytics-label': 'automatic rotation', 'data-analytics-call': ''});
		vm.$container.find('.psv-zoom-button-minus').attr({'data-analytics-label': 'zoom out', 'data-analytics-call': ''});
		vm.$container.find('.psv-zoom-button-plus').attr({'data-analytics-label':'zoom in', 'data-analytics-call': ''});
		vm.$container.find('.psv-zoom-button-handle').attr({'data-analytics-label': 'zoom', 'data-analytics-call': ''});
		vm.$container.find('.custom-button-reset-rotation').attr({'data-analytics-label': 'reset', 'data-analytics-call': ''});
		vm.$container.find('.custom-button-pause-rotation').attr({'data-analytics-label': 'pause', 'data-analytics-call': ''});
		vm.$container.find('.psv-fullscreen-button').attr({'data-analytics-label': 'toggle full screen', 'data-analytics-call': ''});

		vr360Buttons.on('click', $element, (e) => {
			let targetLabel = angular.element(e.currentTarget).attr('data-analytics-label');

			googleTagManager.push({ event: 'gtmCustomEvent', gtmCustomEvent: `view 360 interior - ${targetLabel}` });
		});


		PSV.startAutorotate();
		PSV.stopAutorotate();
		try {
			PSV.zoom(obj360['resetZoom']);
		} catch(exception) {
			console.log('error on zoom', exception.message);
		}

		// navigate to the default position
		if (obj360['defaultX'] > 0 && obj360['defaultY'] > 0) {
			try {
				PSV.animate({
					x: obj360['defaultX'],
					y: obj360['defaultY'],
				}, 2000);
			} catch(exception) {}
		}

		if (vm.modalId > 0) {
			showModal(vm.modalId, '');
		}

		vm.$container.find('.dd360').css('display', 'block');

		let vr360ContactButton = vm.$container.find('.vr360-lfa-link');
		vr360ContactButton.on('click', $element, (e) => {
			let eventObject = {
				category: `${analyticsCategory}`,
				label: `${vm.analyticsLabel} - contact dealer`
			};
		});

		// force redraw to match container size
		$window.dispatchEvent(new Event('resize'));

	};

	vm.showHotSpot = (hotspotID, direction) => {
		showModal(hotspotID, direction);
	};

	vm.showDropdown = (mode) => {
		let dd360 = vm.$container.find('.dd360');

		if (mode) {
			dd360.addClass('activeModule360');
		} else {
			dd360.removeClass('activeModule360');
		}
	};

	vm.populateTrims = () => {
		const object = vm.object;

		if (vm.object.length > 1) {
			let keys = Object.keys(object);

			if (keys.length !== vm.trims.length) { // make sure duplicate trims not added to dropdown
				keys.forEach(function(key, _) {
					vm.trims.push(object[key].name);
				});
			}
		}
	};

	// populate hotspots and model dropdown dynamically on the fly
	vm.populateHotspots = (hotspotsObj) => {
		let object = vm.object;


		vm.populateTrims();

		let trHTML = '<div class="vr360-inst-wrapper">';
		trHTML += `
				<div fca-vr360-ui
					 data-fullscreen-nav-title="${customText.interior360}"
					 data-model-shown="${obj360.name}"
				>
				</div>
			`;
		trHTML += '</div>';

		let uiHTML = '<div class="vr360-inst-wrapper">';
		uiHTML += '<div class="module360Modals">';

		Object.keys(hotspotsObj).forEach(function(key, index) {
			let modalAnalTitle = hotspotsObj[key]['title'].replace(/"/g, '\\\"');
			let defaultLL = '';
			let defaultRR = '';
			let prevIndex = '';
			let nextIndex = '';

			if (key > 0) {
				defaultLL = hotspotsObj[index-1]['title'];
				prevIndex = hotspotsObj[index-1]['id'];
			} else {
				defaultLL = hotspotsObj[(hotspotsObj.length -1)]['title'];
				prevIndex = hotspotsObj[(hotspotsObj.length -1)]['id'];
			}

			if (key != hotspotsObj.length-1) {
				defaultRR = hotspotsObj[index+1]['title'];
				nextIndex = hotspotsObj[index+1]['id'];
			} else {
				defaultRR = hotspotsObj[0]['title'];
				nextIndex = hotspotsObj[0]['id'];
			}

			uiHTML += `
					<div fca-vr360-hotspot
						 id="hotspot-${hotspotsObj[key]['id']}"
						 lang="${modalAnalTitle}"
						 class="module360Modal"
						 style="display:none"
						 data-hotspot-image='${hotspotsObj[key]['image']}'
						 data-hotspot-title='${hotspotsObj[key]["title"]}'
						 data-hotspot-copy='${hotspotsObj[key]["copy"]}'
						 data-hotspot-index='${index}'
						 data-hotspot-alt='${hotspotsObj[key]["alt"]}'
						 data-hotspot-close='${hotspotsObj[key]["close"]}'
						 data-prev-label='${defaultLL}'
						 data-prev-index='${prevIndex}'
						 data-next-label='${defaultRR}'
						 data-next-index='${nextIndex}'
						 data-obj-length='${object.length}'
					></div>
				`;
		});

		uiHTML += '</div></div>';

		var angularObjectTR = angular.element($compile(trHTML)($scope));
		vm.$container.find('.psv-container').prepend(angularObjectTR);
		var angularObject = angular.element($compile(uiHTML)($scope));
		vm.$container.find('.psv-container').append(angularObject);
	};

	// set custom controls tooltip text
	function setCustomText(str, targ) {
		vm.$container.find(targ).addClass('tooltip360');
		vm.$container.find(targ).append('<span class="tooltiptext">'+str+'</span>');
	};

	// close hotspot
	vm.close360Modals = () => {
		vm.$container.find('.modalBackground').hide(500);
		vm.$container.find('.psv-canvas-container').removeClass('blur');
		vm.$container.find('.psv-marker').removeClass('blur');
		vm.$container.find('.module360Modal').fadeOut(500);
		vm.$container.find('.psv-navbar').show();
		vm.$container.find('.wrapper360-demo').show();
		vm.modalId = 0;
		window.removeEventListener('keydown',escapeModal, false);
	};
	// Method recreated from the delete marker from photo sphere lib.
	// The original method was not working in IE.
	vm.deleteMarkers = () => {
		try {
			PSV.clearMarkers();
		} catch(exception) {
			console.log('clear marker error', exception.message);
		}
	};

	// destroy 360 and release from memory
	vm.destroy360 = () => {
		if (!vm.isPSVActive) {
			return;
		}

		vm.deleteMarkers();
		PSV.destroy();
		PSV = '';

		vm.$container.empty().html();
		vm.exitFullscreen();
		vm.isPSVActive = false;

		$scope.$emit('vr360.destroyed');
	};

	vm.toggle360trim = (e) => {
		vm.selected360 = e.$index;
		vm.selectedIndex = e.$index;
	};

	vm.select360trim = (e) => {
		vm.close360Modals();
		vm.selected360 = e.$index;
		vm.selectedIndex = e.$index;
		vm.disableTrimButtons = true;

		obj360 = vm.object[vm.selected360];

		vm.deleteMarkers();

		let label = obj360['name'];

		vm.$container.find('.vr360-inst-wrapper').remove();
		vm.$container.find('.modalBackground').remove();
		vm.$container.find('.wrapper360-demo').remove();
		vm.$container.find('.fullscreenTopnav').remove();
		vm.$container.find('.psv-loader').css('color', '#ccc');
		vm.$container.find('.act-bar-name').html(obj360['name']);

		vm.populateHotspots(obj360['hotspots']);

		try {
			PSV.setPanorama(obj360['image'], true)
				.then(function() {
					vm.ready360();
					$timeout(()=> {
						vm.disableTrimButtons = false;
					});
				});
		} catch(exception) {
			console.log('panorama and then error', exception);
		}
	};

	$element.on('touchstart mousedown', vr360mousedown);

	function vr360mousedown() {
		$document.on('touchmove mousemove', vr360mousemove);
		$document.on('touchend mouseup', vr360mouseup);
	}

	function vr360mouseup() {
		$document.off('touchmove mousemove', vr360mousemove);
		$document.off('touchend mouseup', vr360mouseup);
	}

	function vr360mousemove() {
		vm.showLFA();
	}

	function escapeModal(event) {
		if (event.keyCode == 27) {
			vm.close360Modals()
		}
	}

	// show hotspot
	function showModal(hotspotID, direction) {
		vm.modalId = hotspotID;
		vm.$container.find('.module360Modal').fadeOut(500);

		try {
			PSV.gotoMarker(vm.modalId, 750);
		} catch(exception) {}

		let label = null;

		// Find the label
		let vr360 = vm.object[vm.selected360];
		let hotspotIndex = vm.modalId - 1;
		let vr360Count = vr360.hotspots.length;

		// If there are hotspot and the index is there
		if (vr360.hotspots
			&& (hotspotIndex > -1)
			&& (hotspotIndex < vr360Count)) {
			let hotspot = vr360.hotspots[hotspotIndex];
			// If the hotspot isnt null
			if (hotspot) {
				// Get the analytics prop
				label = hotspot.analyticsLabel;
			}
		}

		if (!label) {
			label = '';
		}

		$timeout(function() {
			$window.addEventListener('keydown',escapeModal, false);
			vm.$container.find('.wrapper360-demo').hide();
			vm.$container.find('.modalBackground').show();
			vm.$container.find('.psv-canvas-container').addClass('blur');
			vm.$container.find('#hotspot-' + vm.modalId + ' .hotspot-close-btn').focus();
			vm.$container.find('.psv-marker').addClass('blur');
			vm.$container.find('#hotspot-' + vm.modalId).fadeIn(500, function() {
				vm.$container.find('#hotspot-' + vm.modalId + ' .hotspot-close-btn').first().focus();
			}).css('display', 'inline-block');
			vm.$container.find('#hotspot-' + vm.modalId + ' .modal-footer-right').first().keydown(function(event) {
				if ((event.shiftKey) && (event.keyCode == 9)) {
					event.preventDefault();
					vm.$container.find('#hotspot-' + vm.modalId + ' .modal-footer-left').first().focus();
				} else if (event.keyCode == 9) {
					event.preventDefault();
					vm.$container.find('#hotspot-' + vm.modalId + ' .hotspot-close-btn').first().focus();
				}
			});
			vm.$container.find('#hotspot-' + vm.modalId + ' .hotspot-close-btn').first().keydown(function(event) {
				if ((event.shiftKey) && (event.keyCode == 9)) {
					event.preventDefault();
					vm.$container.find('#hotspot-' + vm.modalId + ' .modal-footer-right').first().focus();
				}
			});
			vm.$container.find('.psv-navbar').hide();
			vm.$container.find('.module360Modals').css('top',(vm.$container.find('.psv-hud').height() - vm.$container.find('.module360Modals').height()) / 2 + 'px');
		}, 700);
	}

	vm.enterFullscreen = function() {
		vm.$container.addClass('force-fullscreen');
		$document.get(0).body.append(vm.$container.get(0));
		$window.dispatchEvent(new Event('resize'));
	};

	vm.exitFullscreen = function() {
		vm.$container.removeClass('force-fullscreen');
		$element.prepend(vm.$container.get(0));
		$window.dispatchEvent(new Event('resize'));
	};

	vm.triggerActiveSlideContent = function(event, galleryItemId) {
		if (vm.galleryItemId && galleryItemId != vm.galleryItemId) {
			return;
		}
		vm.init360();
	};

	vm.showLFA = function() {
		if ($window.innerWidth > 666) {
			vm.$container.find('.vr360-lfa').slideUp();

			clearTimeout(lfaTimer);
			lfaTimer = setTimeout(function() {
				vm.$container.find('.vr360-lfa').slideDown();
			}, 3000);
		} else {
			vm.$container.find('.vr360-lfa').show();
		}
	}
}
