import angular from "angular";
import config from '../../config';

import template from './fca-chat-popup.content.html'

import './retailer-chat.scss'

export default angular
    .module(`${config.appName}.chat-popup`, [])
    .controller('fcaChatPopupController', FcaChatPopupController)
    .component('fcaChatPopup', {
        template: template,
        controller: 'fcaChatPopupController',
        controllerAs: 'fcaChatPopupCtrl',
        bindings: {
            brand: '@',
            nameplate: '@'
        }
    })
    .name

function FcaChatPopupController($scope, $rootScope, $http, cookieLocation, fcaGeolocator,
                                $cookies, dealerChatService) {
    'ngInject';

    const COOKIE_NAME = config.dealersWithChatCookieName

    this.dealersCookieDaysExpiration = 1;

    $scope.location = cookieLocation.getLocation();

    $scope.dealers = [];

    this.getDealersWithChat = () => {

        let savedDealersWithChat = $cookies.getObject('dealersWithChat');

        // set dealersWithChat cookie if:
        // - cookie does not exist
        // - location is different than the location in the cookie
        // - the dealer list is empty or undefined
        // - Prevent old dealerChat cookie that contained an array instead of an object
        if (savedDealersWithChat === undefined || Array.isArray(savedDealersWithChat) || savedDealersWithChat.dealerList.length === 0 || !angular.equals(savedDealersWithChat.location, $scope.location)) {
            let url = ' /data/dealers/closest-chat?brand=' + this.brand + '&postalCode='
                + $scope.location.postalCode.replace(' ', '+')
                + '&minResults=3&maxResults=3';

            $http.get(url).then((r) => {
                // only keep what we need
                let dealerList = [];
                let cookieObj = {};

                r.data.dealers.forEach(function(data, index) {
                    let dealer = {};
                    dealer.name = data.name;
                    dealer.city = data.city;
                    dealer.province = data.province;
                    dealer.distance = data.distance;
                    dealer.code = data.code;
                    dealer.chatProvider = data.chatProvider;
                    dealer.chatProviderIdEn = data.chatProviderIdEn;

                    dealerList.push(dealer);
                });

                cookieObj.dealerList = dealerList;
                cookieObj.location = $scope.location;

                $scope.dealers = dealerList;

                let today = new Date();
                let todayPlusDays = new Date(today.setDate(
                    today.getDate() + this.dealersCookieDaysExpiration)
                );

                $cookies.remove('dealersWithChat');
                window.FcaCookieChecker.addCookie(COOKIE_NAME, JSON.stringify(cookieObj), '/', todayPlusDays, undefined);

                // only set urls after it's saved in cookie
                this.setDealerChatUrls($scope.dealers, this.brand);
            }).catch(function(e) {
                console.error('error retrieving closest chat dealers', e);
            });
        } else {
            $scope.dealers = savedDealersWithChat.dealerList;
            // set dealers chat urls
            this.setDealerChatUrls($scope.dealers, this.brand);
        }
    };

    this.$onInit = () => {
        /* eslint-disable no-invalid-this */
        if ($scope.location !== null) {
            this.getDealersWithChat();
        }

        /* Listen if the location is changed outside this component */
        let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
        $scope.$on(eventUpdateName, (evt, args) => {
            $scope.location = args[0];
            $cookies.remove(COOKIE_NAME, {path: '/'});
            this.getDealersWithChat();
        });
    };

    this.setDealerChatUrls = (dealerList, brand) => {
        dealerList.forEach(function(dealer, index) {
            let originationUrl = encodeURIComponent(window.location.href);
            dealer.chatUrl = dealerChatService.getDealerChatUrl(dealer.chatProvider, dealer.chatProviderIdEn, dealer.code, originationUrl, brand);
        });
    };

    this.getDealerChatUrl = (dealer) => {
        return dealer.chatUrl;
    };
}


