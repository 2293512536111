import angular from "angular";

import template from './postalCodeInput.html'

import './postalCodeInput.scss'

export default angular
	.module('fca.postalCodeInput', [])
	.controller('fcaPostalCodeInputController', PostalCodeInputController)
	.component('fcaPostalCodeInput', {
		template: template,
		controller: 'fcaPostalCodeInputController',
		controllerAs: '$ctrl',
		bindings: {
			placeholder: '@',
			onSelected: '&'
		}
	})
	.name;

function PostalCodeInputController() {
	'ngInject';

	this.data = null;
	this.isValid = false;
	this.postalCode = '';
	this.displayError = false;

	const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

	this.validatePostalCode = (e) => {
		this.postalCode = e.currentTarget.value;
		this.isValid = regex.exec(this.postalCode);

		if (!this.isValid && this.postalCode.length >= 6) {
			this.displayError = true;
		}

		if (this.isValid) {
			this.displayError = false;
		}
	};

	this.select = ($evt) => {
		$evt.preventDefault();

		if (this.isValid) {
			let data = this.postalCode;

			/* Pass data outside component */
			this.onSelected({
				data: data
			});
		} else {
			this.displayError = true;
		}
	};
}

