export default {
        /* Mediaquery values used for responsive images using <picture> + <source> */
        MOBILE: '(max-width: 767px)',
        MOBILE_RETINA: '(max-width: 767px) and (-webkit-min-device-pixel-ratio: 2),' +
            '(max-width: 767px) and (min--moz-device-pixel-ratio: 2),' +
            '(max-width: 767px) and (-o-min-device-pixel-ratio: 2/1),' +
            '(max-width: 767px) and (min-device-pixel-ratio: 2),' +
            '(max-width: 767px) and (min-resolution: 192dpi),' +
            '(max-width: 767px) and (min-resolution: 2dppx)',
        TABLET: '(min-width: 768px) and (max-width: 1024px)',
        TABLET_RETINA: '(min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),' +
            '(min-width: 768px) and (max-width: 1024px) and (min--moz-device-pixel-ratio: 2),' +
            '(min-width: 768px) and (max-width: 1024px) and (-o-min-device-pixel-ratio: 2/1),' +
            '(min-width: 768px) and (max-width: 1024px) and (min-device-pixel-ratio: 2),' +
            '(min-width: 768px) and (max-width: 1024px) and (min-resolution: 192dpi),' +
            '(min-width: 768px) and (max-width: 1024px) and (min-resolution: 2dppx)',
        DESKTOP: '(min-width: 1025px)',
        DESKTOP_RETINA: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),' +
            '(min-width: 1025px) and (min--moz-device-pixel-ratio: 2),' +
            '(min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1),' +
            '(min-width: 1025px) and (min-device-pixel-ratio: 2),' +
            '(min-width: 1025px) and (min-resolution: 192dpi),' +
            '(min-width: 1025px) and (min-resolution: 2dppx)',

        NOT_RETINA: 'not screen and (-webkit-min-device-pixel-ratio: 2),' +
            'not screen and (min--moz-device-pixel-ratio: 2),' +
            'not screen and (-o-min-device-pixel-ratio: 2/1),' +
            'not screen and (min-device-pixel-ratio: 2),' +
            'not screen and (min-resolution: 192dpi),' +
            'not screen and (min-resolution: 2dppx)',
        RETINA: 'only screen and (-webkit-min-device-pixel-ratio: 2),' +
            'only screen and (min--moz-device-pixel-ratio: 2),' +
            'only screen and (-o-min-device-pixel-ratio: 2/1),' +
            'only screen and (min-device-pixel-ratio: 2),' +
            'only screen and (min-resolution: 192dpi),' +
            'only screen and (min-resolution: 2dppx)'
}

