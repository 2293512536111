import angular from 'angular';

export default angular
	.module('fca.layout.regionalOffers', [])
	.directive('fcaRegionSelector', fcaRegionSelector)
	.name;

function fcaRegionSelector() {
	return {
		restrict: 'A',
		scope: true,
		bindToController: {
			buttonLabel: '@',
			usePageRegion: '@',
			previousRegion: '@'
		},
		controllerAs: 'regionSelector',
		controller: FcaRegionSelectorController
	};

	function FcaRegionSelectorController($scope, $timeout, $rootScope, userLocation, cookieLocation) {
		'ngInject';

		let $ctrl = this;

		$ctrl.$onInit = () => {
			// Watching location change in fca-userLocation.service
			$rootScope.$on('regionalOffers.locationChange', (event, location, province, region, label) => { // eslint-disable-line max-len
				// Save last region
				let locationFromCookie = cookieLocation.getLocation();
				if (locationFromCookie) {
					this.previousRegion = locationFromCookie.region;
				}
				this.updateLabel(label);
			});

			$rootScope.$on('geolocatorStatus.setLocation', (event, region, url) => {
				// If region has changed, redirect on url
				if (region && this.previousRegion && region !== this.previousRegion) {
					window.location.href = url;
				}
			});

			// Timeout is necessary
			$timeout(() => {
				this.updateLabel(userLocation.getLocationLabel());
			});
		};

		this.updateLabel = (label) => {
			this.buttonLabel = label;
		}
	}
}
