import angular from 'angular';
import config from '../../config';

import template from './captcha.template.html'
import CaptchaController from './captcha.controller'

export default angular
    .module(`${config.appName}.captcha`, [])
    .controller('captchaController', CaptchaController)
    .component('catpcha', {
        scope: true,
        template: template,
        controller: 'captchaController',
        controllerAs: '$captchaCtrl',
        bindings: {
            captchaInputAriaLabel: '@'
        }
    })
    .name
