/* @ngInject */
export default class extraBallotsController {
    constructor(contestService) {
        this.contestService = contestService;
    }

    $onInit() {
        // Selected extra ballots vehicles
        this.extraSelection = [];
        this.maxReached = false;
        this.contestService.extraBallotRequired = this.extraBallotRequired;
        this.extraSelectionEmpty = false;
    }

    isSelected(namePlate) {
        let fav = this.extraSelection;
        for (var i=0; i < fav.length; i++){
            if(fav[i].namePlate == namePlate){
                return true;

            }
        }
        return false;
    }

    toggleSelection(namePlate, brand, code) {
        let sel = this.extraSelection;
        // if already a favorite, uncheck/remove
        if(this.isSelected(namePlate)) {
            this.maxReached = false
            for(let i = 0; i < sel.length; i++){
                if (sel[i].namePlate === namePlate) {
                    sel.splice(i, 1);
                    // unless the item exists more than once, break the loop
                    break;
                }
            }
        }
        // otherwise add the item
        else {
            if (sel.length == 4) {
                this.checked = false;
                this.maxReached = true
            } else {
                let newSel = {namePlate: namePlate, brand: brand, code: code};
                sel.push(newSel)
            }
        }
        this.extraSelection = sel;
        this.extraSelectionEmpty = this.extraSelection == 0;
        this.getInterestVehicleList();
    }

    getInterestVehicleList = () => {
        let multipleVois = [];
        for(const values of this.extraSelection) {
            multipleVois.push({code: values.code , name : values.brand});
        }

        this.contestService.userInformation.multipleVois = multipleVois
    }

    extraBallotRequired = () => this.extraSelection.length == 0
}
